
import React,{Component} from 'react'
import {
	Button,
	Label,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	FormText,
	CustomInput,
	ListGroup,
	ListGroupItem,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Spinner
} from 'reactstrap';
import {GState, Superpowers} from './state';
import {
} from 'availity-reactstrap-validation';
import axios from 'axios';

export default class CollectorDetail extends Component {

	// Props:
	//   assettag, a string containing the asset tag (short auth text) of a collector
	//   ok, a function taking no arguments, used to signal we're done with this page.

	state = {
		shorttitle: "",
		description: "",
		staticnetworking: false,
		ip: "",
		gateway: "",
		dns: [],
		firmware: "",
		devmode: false,
		pcapmode: false,
		homeportal: 0,

		firmwarelist: [],
		spinner: false,
		changes: false,
		saved: false
	};


	componentDidMount() {
		axios.post ("/admin/collectorbook", {
			sess: GState.session,
			assettag: this.props.assettag
		})
		.then (r=>{
			this.setState (r.data)
		})
		.catch (e=>{
			alert (e)
		})

		axios.post ("/admin/firmwarelist", {
			sess: GState.session
		})
		.then (r=>{
			this.setState ({firmwarelist:r.data})
		})
		.catch (e=>{
			alert (e)
		})
	}


	savetitle=()=> {
		this.setState ({spinner:true})
		axios.post ("/admin/replacecollectorbook", {
			sess: GState.session,
			assettag: this.props.assettag,
			shorttitle: this.state.shorttitle,
			description: this.state.description,
			staticnetworking: this.state.staticnetworking,
			ip: this.state.ip,
			gateway: this.state.gateway,
			dns: this.state.dns,
			firmware: this.state.firmware,
			devmode: this.state.devmode,
			pcapmode: this.state.pcapmode,
			homeportal: this.state.homeportal
		})
		.then (r=>{
			GState.refreshview++
			this.setState ({changes:false,saved:true,spinner:false})
		})
		.catch (e=>{
			this.setState ({spinner:false})
			alert (e)
		})
	}

	adddns =()=> {
		var a = this.state.dns;
		a.push ("");
		this.setState ({dns:a,changes:true})
	}
	changedns = (ix,v)=> {
		var a = this.state.dns;
		a[ix] = v;
		this.setState ({dns: a,changes:true});
	}
	removedns = (ix)=> {
		var a = this.state.dns;
		a.splice (ix,1);
		this.setState ({dns: a,changes:true});
	}

	dnsentry =(m,ix)=> {
		return <p key={ix}>
			<InputGroup>
			<Input value={m} onChange={e=>{this.changedns(ix,e.target.value)}} />
			<InputGroupAddon addonType="append">
			<Button color="primary" onClick={e=>{this.removedns(ix)}} >Delete</Button>
			</InputGroupAddon>
			</InputGroup>
			</p>
	}

	firmwarelist=()=>{
		const items = this.state.firmwarelist.map ((m,ix)=><DropdownItem key={ix} onClick={e=>{this.setState({firmware:m.Name,changes:true})}} >{m.Name}</DropdownItem>)

		return <UncontrolledDropdown >
				<DropdownToggle caret tag="span" style={{color:"red",cursor:"pointer"}}>
				Select firmware version
				</DropdownToggle>
				<DropdownMenu>
				{items}
				</DropdownMenu>
			</UncontrolledDropdown>
	}

	render() {

		const developer_mode = <Superpowers>
			<ListGroupItem>
				Developer Mode
				<FormGroup >
				<Input className="ml-2" type="checkbox" checked={this.state.devmode} onClick={e=>{this.setState({devmode:!this.state.devmode,changes:true})}} />
				</FormGroup>
				</ListGroupItem>
			</Superpowers>

		const pcap_mode = <Superpowers>
			<ListGroupItem>
				Packet-capture Mode
				<FormGroup >
				<Input className="ml-2" type="checkbox" checked={this.state.pcapmode} onClick={e=>{this.setState({pcapmode:!this.state.pcapmode,changes:true})}} />
				</FormGroup>
				</ListGroupItem>
			</Superpowers>


		const homeportal = <Superpowers>
			<ListGroupItem>
				Assignment to Home Portal
				<div style={styles.smalltext}>When checked, this overrides any portal (Enterprise Edition) assignment</div>
				<FormGroup >
				<Input className="ml-2" type="checkbox" checked={this.state.homeportal} onClick={e=>{this.setState({homeportal:this.state.homeportal?0:1,changes:true})}} />
				</FormGroup>
			</ListGroupItem>
			</Superpowers>




		return <div>
			<span style={{fontSize:"0.8em",color:"steelblue"}}>Asset Tag: {this.props.assettag}</span>

			<ListGroup>

			<ListGroupItem style={{position:"sticky",top:150,zIndex:200}}>
			<Button color={this.state.changes?"danger":"secondary"} size="sm" outline={!this.state.changes} disabled={!this.state.changes} onClick={this.savetitle} >{
				this.state.changes
					? "Save changes"
					: this.state.saved
					? "Changes saved ok"
					: "No changes pending"
			}
			</Button>
			<Spinner color="warning" size="sm" style={{visibility: this.state.spinner ? "visible" : "hidden"}} />
			</ListGroupItem>

			<ListGroupItem>


			<FormGroup>
			<Label>Title</Label>
			<InputGroup>
			<Input value={this.state.shorttitle} onChange={e=>{this.setState({shorttitle:e.target.value,changes:true})}} />
			<InputGroupAddon addonType="append">
			{/*<Button color="primary" onClick={this.savetitle} >Save</Button>*/}
			</InputGroupAddon>
			</InputGroup>
			<FormText>Enter a title that will distinctly identify this Collector in lists and reports</FormText>
			</FormGroup>

			<FormGroup>
			<Label>Description</Label>
			<InputGroup>
			<Input type="textarea" value={this.state.description} onChange={e=>{this.setState({description:e.target.value,changes:true})}} />
			<InputGroupAddon addonType="append">
			{/*<Button color="primary" onClick={this.savetitle} >Save</Button>*/}
			</InputGroupAddon>
			</InputGroup>
			<FormText>Use this to record any additional detail you need about the Collector</FormText>
			</FormGroup>


			</ListGroupItem>


			<ListGroupItem>
			Firmware
			<p>Selected firmware version: {this.state.firmware}</p>
			{this.firmwarelist()}
			</ListGroupItem>



			{developer_mode}
			{pcap_mode}


			{homeportal}

			<ListGroupItem>
			Networking
			<p/>
			<CustomInput label="Use DHCP" checked={!this.state.staticnetworking} type="switch" id="dhcp" onChange={e=>{this.setState({staticnetworking:!e.target.checked,changes:true})}} />
			{
				this.state.staticnetworking
					? <div>

					<FormGroup>
						<Label>Static IP address and mask</Label>
						<Input placeholder="IP address in CIDR format" value={this.state.ip} onChange={e=>{this.setState({ip:e.target.value,changes:true})}} />
					</FormGroup>

					<FormGroup>
						<Label>Default gateway address</Label>
						<Input placeholder="Gateway address" value={this.state.gateway} onChange={e=>{this.setState({gateway:e.target.value,changes:true})}} />
					</FormGroup>

					<p><Button color="link" onClick={this.adddns} >Add DNS address</Button></p>
					{ this.state.dns.map (this.dnsentry) }
					</div>
					: null
			}
			</ListGroupItem>

			</ListGroup>


			</div>
	}

};

const styles = {
	smalltext: {
		fontSize: "0.75em",
		color: "gray"
	}
};


