
import React,{Component} from 'react';
import {
	Spinner,
	Row,
	Col,
	ListGroup,
	ListGroupItem
} from 'reactstrap';
import axios from 'axios';

export default class Entitlements extends Component {

	// Props
	//     session, a string containing a session key

	state = {
		data: [],
		spinner: false
	}

	componentDidMount() {
		this.setState ({spinner:true})
		axios.post ("/admin/entitlementlist", {
			sess: this.props.sess
		})
		.then (r=>{
			this.setState ({data: r.data, spinner:false})
		})
		.catch (e=>{
			this.setState ({spinner:false})
			alert (e)
		})
	}

	render() {
		return <div>
			<h3 className="pageheader">Entitlements</h3>
			<Spinner color="primary" style={{visibility: this.state.spinner ? "visible" : "hidden"}} />

			<ListGroup>
			{
				this.state.data.map ((m,ix)=> {
					return <ListGroupItem className="blush" key={ix}>
						<Row>
						<Col> {ix+1} </Col>
						<Col> {m.Fname}<br/>{m.Lname} </Col>
						<Col> {m.ShortAuthText} </Col>
						<Col> Read:{m.Read ? "Yes" : "No"}<br/>Write:{m.Write ? "Yes" : "No"}<br/> Ext:{m.Extension} </Col>
						</Row>
						</ListGroupItem>
				})
			}
			</ListGroup>

			</div>
	}
};

