
import React,{Component} from 'react';
import {
	Row,
	Col,
	ListGroup,
	ListGroupItem,
	UncontrolledPopover,
	PopoverHeader,
	PopoverBody,
	Label,
	Input,
	Button,
	Spinner,
	Badge,
	CustomInput,
	Form,
	FormGroup,
} from 'reactstrap';
import {GState, Superpowers} from './state';
import axios from 'axios';
import {observer} from 'mobx-react';



/***************
CollectorStatus
***************/

class CollectorStatus extends Component {
	// Props:
	//   serialtext

	state = {
		data: ""
	}

	componentDidMount() {
		axios.post ("/admin/enterprisecollectorstatus", {
			sess: GState.session,
			serialtext: this.props.serialtext
		})
		.then (r=>{
			this.setState ({data: r.data})
		})
		.catch (e=>{
			// no-op
		})
	}

	render() {
		const tgt = "cs_" + this.props.serialtext
		return <div>
			<UncontrolledPopover trigger="legacy" target={tgt}>
				<PopoverHeader>
				<span style={styles.popovertitle}>Collector Status</span>
				</PopoverHeader>
				<PopoverBody>
				{this.state.data}
				</PopoverBody>
			</UncontrolledPopover>
			<Button id={tgt} style={{fontSize:"0.75em"}} color="info" outline >Collectors</Button>
			</div>
	}
}


/**********
NameEditor
**********/

class NameEditor extends Component {
	state = {
		name: this.props.data.Name,
		description: this.props.data.Description,
		editstate: 0, // 0=no edits, 1=edits, 2=saved
		spinner: false
	}

	save=()=>{
		if (this.state.editstate !== 1)
			return
		this.setState ({spinner:true})
		axios.post ("/admin/editenterpriseedition", {
			sess: GState.session,
			serialtext: this.props.data.Serialtext,
			name: this.state.name,
			description: this.state.description
		})
		.then (r=>{
			this.setState ({spinner:false, editstate:2})
			this.props.triggerrefresh()
		})
		.catch (e=>{
			this.setState ({spinner:false})
			alert (e)
		})
	}

	render() {
		return <div>
			<Label>Name</Label>
			<Input value={this.state.name} onChange={e=>{this.setState({editstate:1, name:e.target.value})}} />
			<Label>Description</Label>
			<Input value={this.state.description} onChange={e=>{this.setState({editstate:1, description:e.target.value})}} />
			<div style={{height:"1em"}} />
			<Button size="sm" disabled={this.state.editstate !== 1} color="primary" outline={this.state.editstate !== 1} onClick={this.save} >{this.state.editstate===2 ? "Saved ok":"Save"}</Button>
			<Spinner size="sm" color="warning" style={{visibility:this.state.spinner?"visible":"hidden"}} />
			</div>
	}
}


/********
AgeBadge
********/

const AgeBadge = (props) => {

	const target = "ab_" + props.statrec.Serialtext

	const badge = props.statrec.Age === undefined
		? <Badge>Status Unknown</Badge>
		: props.statrec.Age <= 120
		? <Badge style={{cursor:"pointer"}} color="success" id={target} >Active</Badge>
		: <Badge style={{cursor:"pointer"}} color="danger" id={target} >Inactive</Badge>

	var deets = []
	var n = 1
	for (const k in props.statrec) {
		if (k === "Status") {
			for (const k2 in props.statrec.Status) {
				deets.push (<div key={n++} style={styles.smalltitle}>{k2}</div>)
				deets.push (<div key={n++} style={styles.smalltext}>{props.statrec.Status[k2]}</div>)
				deets.push (<div key={n++} style={{height:"1em"}} />)
			}
		}
		else {
			deets.push (<div key={n++} style={styles.smalltitle}>{k}</div>)
			deets.push (<div key={n++} style={styles.smalltext}>{props.statrec[k]}</div>)
			deets.push (<div key={n++} style={{height:"1em"}} />)
		}
	}


	const popover = props.statrec.Serialtext
		? <UncontrolledPopover trigger="legacy" target={target}>
			<PopoverHeader style={styles.popovertitle}>
			Status Details
			</PopoverHeader>
			<PopoverBody>
			{deets}
			</PopoverBody>
			</UncontrolledPopover>
		: null

	const version = props.statrec.Status && props.statrec.Status.build
		? <Badge color="info">{props.statrec.Status.build}</Badge>
		: <Badge>Version unknown</Badge>

	return <div>{badge}{popover}{" "}{version}</div>
}


/****************
EnterpriseEdSumm
****************/

class EnterpriseEdSumm extends Component {

	// Props:
	//   data
	//   statrec
	//   principals

	state = {
		tunnel_port: this.props.data.TunnelPort
	}

	update = ()=> {
		axios.post ("/admin/enterprisescheduleupdate", {
			sess: GState.session,
			serialtext: this.props.data.Serialtext
		})
		.then (r=>{
			alert ("Ok")
		})
		.catch (e=>{
			alert (e)
		})
	}

	change_tunnel = ()=> {
		axios.post ("/admin/enterprisesettunnelport", {
			sess: GState.session,
			serialtext: this.props.data.Serialtext,
			tunnelport: this.state.tunnel_port
		})
		.then (r=>{
			alert ("Ok")
		})
		.catch (e=>{
			alert (e)
		})
	}

	render() {
		const peeps = this.props.principals ? this.props.principals.map ((m,ix)=><div style={styles.smalltext} key={ix}>{m.Fname}{" "}{m.Lname}</div>) : null;


		const links = <div>
			<CollectorStatus serialtext={this.props.data.Serialtext} />
			<div style={{height:"0.2em"}} />
			<Button style={{fontSize:"0.75em"}} color="info" outline onClick={this.owners} >Owners</Button>
			{" "}
			<Button style={{fontSize:"0.75em"}} color="info" outline onClick={this.update} >Schedule Update</Button>
			</div>

		const tunnel = <div>
			<Form inline>
			<FormGroup>
			<Label size="sm" style={{color:"gray"}} >Tunnel port: </Label>
			<Input size="sm" type="number" value={this.state.tunnel_port} onChange={e=>{this.setState({tunnel_port:e.target.value})}} />
			</FormGroup>
			<Button size="sm" onClick={this.change_tunnel} color="link">Change</Button>
			</Form>
			</div>

		return <ListGroupItem className="blush">
			<Row>

			<Col>
				<div style={styles.heading}>{ this.props.data.Name === "" ? "[unnamed]" : this.props.data.Name}</div>
				<div style={styles.smalltext}>{this.props.data.Description}</div>
				<div style={{padding:"0.5em"}} />
				{peeps}
				<div style={{padding:"0.5em"}} />
				{links}
				<Superpowers>
					<div style={{padding:"0.5em"}} />
					{tunnel}
				</Superpowers>
			</Col>

			<Col>
			<div style={{textAlign:"right"}}>
				<div><span style={styles.smalltitle}>Created: </span><span style={styles.smalltext}>{this.props.data.CreatedAt}</span></div>
				<div><span style={styles.smalltitle}>Remote IP: </span><span style={styles.smalltext}>{this.props.statrec.RemoteIp}</span></div>
				<div><AgeBadge statrec={this.props.statrec} /></div>
			</div>
			</Col>

			</Row>
			</ListGroupItem>
	}

	xxxrender() {

		const pr = this.props.principals[this.props.data.Ix];
		const peeps = pr ? pr.map ((m,ix)=><div style={styles.smalltext} key={ix}>{m.Fname}{" "}{m.Lname}</div>) : null;

		const sr = this.props.statrecs[this.props.data.Serialtext]
			? <div><span style={styles.smalltitle}>Last status: </span><span style={styles.smalltext}>{this.props.statrecs[this.props.data.Serialtext]}</span></div>
			: null
			;
	/*
		const remip = this.props.remoteips [this.props.data.Serialtext]
			? <div><span style={styles.smalltitle}>Remote IP: </span><span style={styles.smalltext}>{this.props.remoteips [this.props.data.Serialtext]}</span></div>
			: null
*/

		const poptarget = "summ_" + this.props.data.Serialtext;

		return <ListGroupItem className="blush">
			<Row>

			<Col>
				<div id={poptarget} style={{cursor:"pointer"}}>
				<div style={styles.heading}>{ this.props.data.Name === "" ? "[unnamed]" : this.props.data.Name}</div>
				<div style={styles.smalltext}>{this.props.data.Description}</div>
				</div>
				<div style={{padding:"0.5em"}} />
				{peeps}
				<UncontrolledPopover trigger="legacy" target={poptarget} placement="top-start">
					<PopoverHeader style={{color:"steelblue"}}>
					Edit name and description
					</PopoverHeader>
					<PopoverBody>
						<NameEditor data={this.props.data} triggerrefresh={this.props.triggerrefresh} />
					</PopoverBody>
				</UncontrolledPopover>
			</Col>

			<Col>
				<div style={{textAlign:"right"}}>
					<div><span style={styles.smalltitle}>Created: </span><span style={styles.smalltext}>{this.props.data.CreatedAt}</span></div>
					{sr}
					<div style={{color:"red"}}> {this.props.data.Age} {this.props.data.RemoteIp}</div>
				</div>
			</Col>

			</Row>
			</ListGroupItem>
	}
}


/****************
EnterpriseEdList
****************/

export const EnterpriseEdList = observer (class EnterpriseEdList extends Component {

	state = {
		eelist: [],
		principals: {},
		statrecs: {},
		hideinactives: true
	}


	componentDidMount() {
		this.refresh()
		this.interval = setInterval (this.refresh, 30000)
	}
	componentWillUnmount() {
		clearInterval (this.interval)
	}

	refresh=()=> {
		axios.post ("/admin/enterpriseeditionlist", {
			sess: GState.session
		})
		.then (r=>{
			var p = {}
			r.data.princs.forEach (m => {
				if (p[m.Eix] === undefined)
					p[m.Eix] = []
				p[m.Eix].push (m)
			})
			var sr = {}
			r.data.statrecs.forEach (m => {
				m.Status = JSON.parse (m.Status)
				sr[m.Serialtext] = m
			})
			//console.log (r.data.eelist)
			this.setState ({eelist:r.data.eelist, principals:p, statrecs:sr})
		})
		.catch (e=>{
			// don't complain, to avoid a message when the session times out
			//alert (e)
		})
	}

	render() {
		return <div>
				<h3 className="pageheader">Enterprise Editions (Admin)</h3>

				<CustomInput label="Hide Inactives" checked={this.state.hideinactives} id="inactives" type="switch" onChange={e=>{this.setState({hideinactives:!this.state.hideinactives})}} />
				<div style={{height:"1em"}} />

				<ListGroup>
				{ this.state.eelist.map ((m,ix) => {
						const statrec = this.state.statrecs[m.Serialtext] ? this.state.statrecs[m.Serialtext] : {}
						return (!this.state.hideinactives || (statrec.Age && statrec.Age <= 120))
							?  <EnterpriseEdSumm
								key={ix}
								data={m}
								statrec={statrec}
								principals={this.state.principals[m.Ix]}
								triggerrefresh={this.refresh}
								/>
							: null
						})
				}
				</ListGroup>

			</div>
	}
});


const styles = {
	heading: {
		fontSize: "1.3em",
		color: "gray",
		paddingBottom: "0.2em"
	},
	smalltext: {
		fontSize: "0.75em",
		color: "gray",
	},
	smalltitle: {
		fontSize: "0.75em",
		color: "black",
	},
	popovertitle: {
		color: "steelblue"
	}
}

