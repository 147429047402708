import React,{Component} from 'react';
import './App.css';
import CollectorList from './collectorlist';
import Firmware from './firmware';
import Entitlements from './entitlements';
import Principals from './principals';
import Captures from './captures';
import CaptureSets from './capturesets';
import SessionWatchdog from './sessionwatchdog';
import EnterpriseEd from './enterpriseed';
import {EnterpriseEdList} from './entedlist';
import {Reports} from './reports';
import {EE, Superpowers} from './state';
import {GState} from "./state";
import Crystal from './img/CrystalBackground.jpg';
import SystemStatus from './systemstatus';
import {
	Nav,
	Navbar,
	NavbarBrand,
	NavLink,
	NavbarToggler,
	Collapse,
	Toast,
	ToastHeader,
	ToastBody
} from 'reactstrap';

import axios from 'axios';
//import {observer} from 'mobx-react';


export default class MainPage extends Component {

	// Props:
	//   sesskey, a string containing a session key
	//   sessprops, an object with some additional properties
	//   signout, a function taking no args that forgets the session key

	state = {
		tab: "collectors",
		hamburger: false
	}


	signout=()=>{
		axios.post ("/admin/signout", {
			sess: this.props.sess
		})
		.finally (r=>{
			this.props.signout()
		})
	}

	componentDidMount() {
		this.checksuperpowers()
		this.checkenterpriseedition()
	}

	checksuperpowers=()=> {
		axios.post ("/admin/checksuperpowers", {
			sess: this.props.sess
		})
		.then (r=>{
			GState.superpowers = r.data.superpowers;
		})
		.catch (()=>{
			GState.superpowers = false;
		})
	}

	checkenterpriseedition=()=> {
		axios.post ("/admin/checkenterpriseedition", {
			sess: this.props.sess
		})
		.then (r=>{
			GState.enterpriseedition = r.data.enterpriseedition;
		})
		.catch (e=>{
			GState.enterpriseedition = false;
		})
	}



	render() {
		// Note that we make the top strip css-sticky so it will remain visible as the user
		// scrolls the page. A z-index value of 100 is chosen to be more than the ListGroup and
		// other react-strap items; and less than the Modal.

		return <div>

			<SessionWatchdog sess={this.props.sess} signout={this.signout} />

			<div style={{position:"sticky",top:0,zIndex:100}}>
			<div className="namebar">Hello, {GState.sessprops.fname} {GState.sessprops.lname}</div>
			<Navbar className="hybrid" dark>

			<NavbarBrand style={{color:"white"}} >

				<div style={{display:"inline-block",verticalAlign:"middle",marginRight:"1em",borderRight:"1px solid skyblue",paddingRight:"1em"}}>
					<div style={{fontSize:"1.3em"}}>
					IOT Monitor<span style={{fontSize:"0.6em",verticalAlign:"top"}}>&reg;</span>
					</div>

					<div style={{color:"crimson",textShadow:"1px 1px 1px darkred",fontSize:"0.7em",fontVariant:"small-caps"}}>
					<EE>Enterprise Edition</EE>
					<EE reverse>Cloud Edition</EE>
					</div>

				</div>
				<div style={{display:"inline-block",verticalAlign:"middle"}}>
				<span className="icglink" onClick={e=>{window.open("https://www.insightcybergroup.com","_blank")}}>  a service of Insight Cyber Group</span>
				</div>

			</NavbarBrand>

			<NavbarToggler onClick={e=>{this.setState({hamburger:!this.state.hamburger})}} />

			<Collapse isOpen={this.state.hamburger} navbar>
				<Navbar>
				<NavLink className="tablink" style={{color:"white"}} onClick={()=>{this.setState({tab:"system"})}} >System</NavLink>
				<NavLink className="tablink" style={{color:"white"}} onClick={this.signout} >Signout</NavLink>
				</Navbar>
				<p className="icglink" onClick={e=>{window.open("https://www.insightcybergroup.com","_blank")}}>Copyright &copy; 2017-20 by Insight Cyber Group, LLC. All Rights Reserved</p>
			</Collapse>
			</Navbar>


			<Nav tabs style={{color:"gold",textShadow:"1px 1px 1px steelblue",padding:"0px",backgroundImage:`url(${Crystal})`}}>
			<NavLink className="tablink" onClick={e=>{this.setState({tab:"reports"})}} >Reports</NavLink>
			<NavLink className="tablink" onClick={e=>{this.setState({tab:"collectors"})}} >Collectors</NavLink>
			<NavLink className="tablink" onClick={e=>{this.setState({tab:"capturesets"})}} >Captures</NavLink>
			{/*<NavLink className="tablink" onClick={e=>{this.setState({tab:"captures"})}} >Capture Files</NavLink>*/}
			<Superpowers>
				<NavLink className="tablink" onClick={e=>{this.setState({tab:"firmware"})}} >Firmware Updates</NavLink>
				<NavLink className="tablink" onClick={e=>{this.setState({tab:"entitlements"})}} >Entitlements</NavLink>
				<NavLink className="tablink" onClick={e=>{this.setState({tab:"principals"})}} >Principals</NavLink>
				<NavLink className="tablink" onClick={e=>{this.setState({tab:"enteds"})}} >EntEds</NavLink>
			</Superpowers>
			<EE><NavLink className="tablink" onClick={e=>{this.setState({tab:"enterpriseedition"})}} >Enterprise Ed</NavLink></EE>
			</Nav>

			<div style={{height:"2px"}} />
			</div>



			<div className="fader bg-info rounded">
				<Toast>
				<ToastHeader icon="info">Welcome back, {this.props.sessprops.fname}{" "}{this.props.sessprops.lname}</ToastHeader>
				<ToastBody>
				<Superpowers>You have superpowers!</Superpowers>
				</ToastBody>
				</Toast>
			</div>


			{
				this.state.tab === "collectors"
					? <CollectorList sess={this.props.sess} />
					: this.state.tab === "reports"
					? <Reports />
					: this.state.tab === "firmware"
					? <Firmware sess={this.props.sess} />
					: this.state.tab === "entitlements"
					? <Entitlements sess={this.props.sess} />
					: this.state.tab === "capturesets"
					? <CaptureSets />
					: this.state.tab === "captures"
					? <Captures sess={this.props.sess} />
					: this.state.tab === "principals"
					? <Principals sess={this.props.sess} />
					: this.state.tab === "enteds"
					? <EnterpriseEdList />
					: this.state.tab === "enterpriseedition"
					? <EnterpriseEd sess={this.props.sess} />
					: this.state.tab === "system"
					? <SystemStatus />
					: null
			}
			<div style={{height:"1em"}} />

		</div>


	}
};


