import React,{Component} from 'react';
import {
	AvForm,
	AvGroup,
	AvInput
} from 'availity-reactstrap-validation';
import {
	Button,
	FormGroup,
	Label,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	Collapse
} from 'reactstrap';
import SignUp from './signup';
import Forgot from './forgot';
import axios from 'axios';
import Crystal from './img/CrystalBackground.jpg';
import {EE} from './state';

const sha256 = require ('js-sha256').sha256;


/************
class Signin
************/

export default class SignIn extends Component {

	// Props
	//   setsession, a function taking a string and an optional object with session parameters

	state = {
		email: "",
		psw: "",
		nonce: "",
		code: "",
		signup: false,
		forgot: false
	}

	login = e=> {
		e.preventDefault();
		axios.post ("/admin/signin", {
			email: this.state.email,
			psw: this.state.psw
		})
		.then (r=>{
			if (! r.data.nonce)
				alert ("problem on the server");
			else
				this.setState ({nonce:r.data.nonce})
		})
		.catch (r=>{
			alert ("Incorrect, try again")
		})
	}

	nonce = e=> {
		e.preventDefault();
		const key = sha256 (this.state.nonce + this.state.code)

		axios.post ("/admin/codeverify", {
			email: this.state.email,
			psw: this.state.psw,
			code: this.state.code,
			key: key
		})
		.then (r=>{
			this.props.setsession (key, r.data)
		})
		.catch (r=>{
			alert ("Incorrect, try again")
		})
	}




	render() {
		return <div>

			<Navbar className="hybrid" dark>

			<NavbarBrand style={{color:"white"}} >
				<div style={{display:"inline-block",verticalAlign:"middle",marginRight:"1em",borderRight:"1px solid skyblue",paddingRight:"1em"}}>
					<div style={{fontSize:"1.3em"}}>
					IOT Monitor<span style={{fontSize:"0.6em",verticalAlign:"top"}}>&reg;</span>
					</div>
					<EE>
					<div style={{color:"crimson",textShadow:"1px 1px 1px darkred",fontSize:"0.7em",fontVariant:"small-caps"}}>
					Enterprise Edition
					</div>
					</EE>
				</div>
				<div style={{display:"inline-block",verticalAlign:"middle"}}>
				<span className="icglink" onClick={e=>{window.open("https://www.insightcybergroup.com","_blank")}}>  a service of Insight Cyber Group</span>
				</div>
			</NavbarBrand>

			<NavbarToggler onClick={e=>{this.setState({hamburger:!this.state.hamburger})}} />
			<Collapse isOpen={this.state.hamburger} navbar>
				<p className="icglink" onClick={e=>{window.open("https://www.insightcybergroup.com","_blank")}}>Copyright &copy; 2017-20 by Insight Cyber Group, LLC. All Rights Reserved</p>
			</Collapse>
			</Navbar>

			<div style={{height:"3em",backgroundImage:`url(${Crystal})`}} />

			<div style={{height:"2em"}} />

			{ this.state.signup
				? <SignUp done={e=>{this.setState({signup:false})}} />
				: this.state.forgot
				? <Forgot done={e=>{this.setState({forgot:false})}} />
				: this.state.nonce
				? <div style={{color:"gray"}}>
					<div style={{marginLeft:"1em"}}>
					<AvForm onSubmit={this.nonce}>
					<AvGroup>
					<Label for="code" >Security code from your phone</Label>
					<AvInput autoFocus name="code" id="code" required value={this.state.code} onChange={e=>{this.setState({code:e.target.value})}} />
					</AvGroup>

					<FormGroup>
					<Button color="primary" type="submit">Submit</Button>
					</FormGroup>
					</AvForm>
					</div>
					</div>
				: <div style={{color:"gray"}}>
					<div style={{marginLeft:"1em"}}>
					<AvForm onSubmit={this.login}>

					<AvGroup>
					<Label for="email" >Email Address</Label>
					<AvInput autoFocus name="email" id="email" required value={this.state.email} onChange={e=>{this.setState({email:e.target.value})}} />
					</AvGroup>

					<AvGroup>
					<Label for="psw">Password
					<Button color="link" style={{fontSize:"0.65em"}} onClick={e=>{this.setState({forgot:true})}} >(Forgot?)</Button>
					</Label>
					<AvInput type="password" name="psw" id="psw" required value={this.state.psw} onChange={e=>{this.setState({psw:e.target.value})}} />
					</AvGroup>

					<FormGroup>
					<Button color="primary" type="submit">Sign In</Button>
					</FormGroup>

					</AvForm>
					</div>

					<p style={{borderTop:"1px solid teal", padding: "5px"}}>
					<Button color="link" style={{color:"gray",fontSize:"0.8em"}}>No login?</Button>
					<Button color="link" style={{fontSize:"0.8em"}} onClick={e=>{this.setState({signup:true})}}>Set up an account</Button>
					</p>

					</div>
			}

		</div>
	}

};

