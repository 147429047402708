

import React,{Component} from 'react';



export default class CaptureSetReports extends Component {

	// Props:
	//   sess, a string with a session
	//   shortname, a string identifying a capture set

	state = {
	};

	render() {
		return <div>REPORTS...</div>
	}
};

