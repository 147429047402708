
import React,{Component} from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Progress,
	Stylesheet,
	ListGroup,
	ListGroupItem
} from 'reactstrap';
import axios from 'axios';
import ReactFileReader from 'react-file-reader';

export default class CaptureSetFiles extends Component {

	// Props:
	//   sess, a string with a session
	//   shortname, a string identifying a capture set

	state = {
		list: [],
		upload: false,
		fileobject: null,
		progress: 0
	};

	componentDidMount() {
		this.refreshlist()
	}

	refreshlist = ()=> {
		axios.post ("/admin/capturesetfilelist", {
			sess: this.props.sess,
			shortname: this.props.shortname
		})
		.then (r=>{
			console.log (r.data)
			this.setState ({list:r.data})
		})
		.catch (e=>{
			alert (e)
		})
	}

	go=()=> {
		var fd = new FormData()
		fd.append ("file", this.state.fileobject, this.state.fileobject.name)
		fd.append ("shortname", this.props.shortname)
		fd.append ("session", this.props.sess)

		axios.post ("/admin/postcapture", fd, {
			onUploadProgress: p => {
				const g = Math.floor ((p.loaded / p.total) * 100.0)
				this.setState ({progress: g})
				this.refreshlist()
			}
		})
		.then (r=>{
			alert ("ok")
			this.setState ({upload:false})
		})
		.catch (e=>{
			alert (e)
		})
	}

	hf = files => {
		this.setState ({fileobject: files[0]})
	}

	render() {
		return <div>
			<h3>Capture-Set Files</h3>
			<p>{this.props.shortname}</p>
			<p>
				<Button color="link" onClick={e=>{this.props.ok()}}>Return to List</Button>
				{" "}
				<Button color="link" onClick={e=>{this.setState({upload:true})}}>Upload Capture File</Button>
			</p>

			<ListGroup>
			{ this.state.list.map ((m,ix)=> <ListGroupItem key={ix}>
					{m.Name}
					</ListGroupItem>)
			}
			</ListGroup>

			<Modal isOpen={this.state.upload}>
			<ModalHeader style={{backgroundColor:"steelblue",color:"white"}}>Upload Capture File</ModalHeader>
			<ModalBody>
				<ReactFileReader handleFiles={this.hf} fileTypes="*.*">
				<Button color="primary" size="small">Choose file to upload</Button>
				<p style={styles.filename} >{this.state.fileobject ? this.state.fileobject.name : ""}</p>
				</ReactFileReader >
				<Progress color="primary" animated value={this.state.progress} >{ this.state.progress ? (""+this.state.progress+"%") : ""}</Progress>
			</ModalBody>
			<ModalFooter>
				<Button disabled={this.state.fileobject===null} outline={this.state.fileobject===null} onClick={this.go} >Ok</Button>
				{" "}
				<Button onClick={()=>{this.setState({upload:false})}} >Cancel</Button>
			</ModalFooter>
			</Modal>

			</div>
	}
};


const styles = {
	filename: {
		fontSize: "small",
		color: "steelblue"
	},
	modalheader: {
		backgroundColor: "steelblue",
		color: "white"
	}
};

