
import React,{Component} from 'react';
import {
	ListGroup,
	ListGroupItem,
	Spinner,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Progress
} from 'reactstrap';
import axios from 'axios';
import ReactFileReader from 'react-file-reader';
import CaptureReport from './capturereport';

export default class Captures extends Component {

	// Props:
	//   sess, a string denoting a session key
	state={
		spinner: false,
		upload: false,
		caps: [],
		fileobject: null,
		progress: 0,
		deletefile: "",
		reportfile: ""
	}

	componentDidMount() {
		this.refresh()
	}

	refresh=()=>{
		this.setState ({spinner:true})
		axios.post ("/admin/capturelist", {
			sess: this.props.sess
		})
		.then (r=>{
			this.setState ({spinner:false, caps:r.data})
		})
		.catch (e=>{
			alert (e)
		})
	}

	hf = files => {
		this.setState ({fileobject: files[0]})
	}

	go = e => {
		var fd = new FormData()
		fd.append ("file", this.state.fileobject, this.state.fileobject.name)
		fd.append ("session", this.props.sess)

		axios.post ("/admin/postcapture", fd, {
			onUploadProgress: p => {
				const g = Math.floor ((p.loaded / p.total) * 100.0)
				this.setState ({progress: g})
			}
		})
		.then (r=>{
			this.setState ({upload:false})
			this.refresh()
		})
		.catch (r=>{
			const d = (r.response && r.response.data) ? ("\n"+r.response.data) : ""
			alert (r + d)
		})
	}

	deletefile = ()=> {
		const fn = this.state.deletefile
		this.setState ({deletefile:"", spinner:true})
		axios.post ("/admin/deletecapture", {
			sess: this.props.sess,
			filename: fn
		})
		.then (r=>{
			this.refresh()
		})
		.catch (e=>{
			this.setState ({spinner:false})
			alert (e)
		})
	}

	report = filename=> {
		this.setState ({reportfile:filename})
	}

	render() {
		return this.state.reportfile !== ""
			? <CaptureReport sess={this.props.sess} file={this.state.reportfile} ok={e=>{this.setState({reportfile:""})}} />
			: this.renderlist()
			;
	}

	renderlist = ()=> {
		const caps = this.state.caps.map ((m,ix)=><ListGroupItem className="snow" key={ix}>
				<div style={{fontSize:"1.2em", color:"gray", paddingBottom:"0.2em"}} >{m}</div>
				<div>
					<Button color="link" style={styles.smalllink} onClick={e=>{this.report(m)}}>Report</Button>
					<Button color="link" style={styles.smalllink} onClick={e=>{this.setState({deletefile:m})}}>Delete</Button>
				</div>
			</ListGroupItem>)

		return <div>
			<h3 className="pageheader">Capture Files</h3>

			<Spinner color="warning" size="sm" style={{visibility: this.state.spinner ? "visible" : "hidden"}} />

			<Button color="link" onClick={e=>{this.setState({upload:true,fileobject:null,progress:0})}}>Upload Capture File</Button>
			<ListGroup>
			{caps}
			</ListGroup>

			<Modal isOpen={this.state.upload}>
			<ModalHeader style={styles.modalheader} >
			Upload Capture File
			</ModalHeader>
			<ModalBody>
				<ReactFileReader handleFiles={this.hf} fileTypes="*.*">
				<Button color="primary" size="small">Choose file to upload</Button>
				<p style={styles.filename} >{this.state.fileobject ? this.state.fileobject.name : ""}</p>
				</ReactFileReader >
				<Progress color="primary" animated value={this.state.progress} >{ this.state.progress ? (""+this.state.progress+"%") : ""}</Progress>
			</ModalBody>
			<ModalFooter>
				<Button disabled={this.state.fileobject===null} color="primary" outline={this.state.fileobject===null} onClick={this.go} >Ok</Button>
				<Button onClick={e=>{this.setState({upload:false})}} >Cancel</Button>
			</ModalFooter>
			</Modal>

			<Modal isOpen={this.state.deletefile !== ""} color="alert" >
			<ModalBody><p className="mb-0">Are you sure?</p></ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={this.deletefile}>Ok</Button>
				<Button color="secondary" onClick={e=>{this.setState({deletefile:""})}}>Cancel</Button>
			</ModalFooter>
			</Modal>


			</div>
	}
}


const styles = {
	filename: {
		fontSize: "small",
		color: "steelblue"
	},
	modalheader: {
		backgroundColor: "steelblue",
		color: "white"
	},
	smalllink: {
		fontSize: "0.75em",
		color: "darkorange"
	},
};

