import React, { Component } from 'react';
import './App.css';
import {
		Container,
		Row,
		Col
} from 'reactstrap';
import {observer} from 'mobx-react';
//import SigninSignup from './signinsignup';
import Signin from './signin';
import MainPage from './mainpage';
import {GState} from './state';
import Heat from './heat';
import TimePicker from './timepicker';
//import Firmware from './firmware';
//import CollectorList from './collectorlist';
//import CollectorDetail from './collectordetail';
//import CollectorReports from './collectorreports';
//import Entitlements from './entitlements';
//import CaptureSets from './capturesets';
//import Captures from './captures';
//import Picture from './picture';


class App extends Component {

	constructor() {
		super();
		this.state = {
			sesskey: "",
			sessprops: {}
		};
	}

	setsession = (sesskey, sessprops) => {
		GState.session = sesskey
		GState.sessprops = sessprops
		//this.setState ({sesskey:sesskey, sessprops:sessprops})
	}


	render() {

		//return <div style={{padding:"2em",border:"1px solid lightgray",margin:"4em"}}><TimePicker /></div>
		//return <Heat />

		//return <div style={{padding:"5em"}}><CollectorDetail assettag="ISROOFCV" sess="copperhead" ok={()=>{alert("bounce")}} /></div>
		//return <div style={{padding:"5em"}}><CollectorList sess="copperhead" /></div>
		//return <div style={{padding:"5em"}}><CollectorReports sess="copperhead" assettag="GBGRTSEQ"/></div>
		//return <div style={{padding:"5em"}}><Entitlements sess="copperhead"/></div>
		//return <div style={{padding:"5em"}}><CaptureSets sess="copperhead"/></div>
		//return <div style={{padding:"5em"}}><Captures sess="copperhead"/></div>
		//return <div style={{padding:"5em"}}><Picture sess="copperhead"/></div>

		const Page = observer (()=>{
			return GState.session
				? <MainPage sess={GState.session} sessprops={GState.sessprops} signout={()=>{this.setsession("",{})}} />
				: <Signin setsession={this.setsession} />
		})

		return <div className="App">
			<Container fluid={true}>

			<Row>
			<Col sm="1" />
			<Col>
			<Page/>
			{/*
				GState.session
					? <MainPage sess={this.state.sesskey} sessprops={this.state.sessprops} signout={()=>{this.setsession("",{})}} />
					: <Signin setsession={this.setsession} />
			*/}
			</Col>
			<Col sm="1" />
			</Row>

			</Container>
			</div>
	}

}

export default App;
