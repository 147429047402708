
import React,{Component} from 'react';
import './App.css';
import {
	Form,
	Button,
	Input,
	Label,
	FormGroup,
	Col
} from 'reactstrap';
import axios from 'axios';

export default class Signup extends Component {

	// Props:
	//   done, a function with no arguments which tells the caller we're done here

	state = {
		fname:"",
		lname:"",
		email1:"",
		email2:"",
		phone:"",
		psw1:"",
		psw2:"",
		org:""
	}

	is_valid = ()=> {
		return this.state.fname &&
			this.state.lname &&
			this.state.email1 &&
			this.state.email1 === this.state.email2 &&
			this.state.phone &&
			this.state.psw1 &&
			this.state.psw1 === this.state.psw2
			;
	}

	go = e => {
		e.preventDefault();

		const t = {
			fname: this.state.fname,
			lname: this.state.lname,
			email: this.state.email1,
			phone: this.state.phone,
			psw: this.state.psw1,
			org: this.state.org
		};

		axios.post ("/admin/signup", t)
			.then (r=>{
				alert ("Account created. Click Ok to sign in using your new credentials")
				this.props.done()
			})
			.catch (r=>{
				alert ("Error: " + r)
			})
	}


	render() {
		return <div>
			<h3 className="pageheader">Set up an account</h3>
			<Form onSubmit={this.go}>

			<FormGroup row>
			<Label sm="2">First Name</Label>
			<Col sm="10">
			<Input autoFocus placeholder="Required" value={this.state.fname} onChange={e=>{this.setState({fname:e.target.value})}} />
			</Col>
			</FormGroup>

			<FormGroup row>
			<Label sm="2">Last Name</Label>
			<Col sm="10">
			<Input value={this.state.lname} placeholder="Required" onChange={e=>{this.setState({lname:e.target.value})}} />
			</Col>
			</FormGroup>

			<FormGroup row>
			<Label sm="2">Email Address</Label>
			<Col sm="10">
			<Input value={this.state.email1} placeholder="Required" onChange={e=>{this.setState({email1:e.target.value})}} />
			</Col>
			</FormGroup>

			<FormGroup row>
			<Label sm="2">Email Address (again)</Label>
			<Col sm="10">
			<Input value={this.state.email2} placeholder="Required" onChange={e=>{this.setState({email2:e.target.value})}} />
			</Col>
			</FormGroup>

			<FormGroup row>
			<Label sm="2">Cell Phone</Label>
			<Col sm="10">
			<Input value={this.state.phone} placeholder="Required" onChange={e=>{this.setState({phone:e.target.value})}} />
			</Col>
			</FormGroup>

			<FormGroup row>
			<Label sm="2">Password</Label>
			<Col sm="4"><Input type="password" value={this.state.psw1} onChange={e=>{this.setState({psw1:e.target.value})}} /></Col>
			<Label sm="2">(Again)</Label>
			<Col sm="4"><Input type="password" value={this.state.psw2} onChange={e=>{this.setState({psw2:e.target.value})}} /></Col>
			</FormGroup>

			<FormGroup row>
			<Label sm="2">Organization</Label>
			<Col sm="10">
			<Input value={this.state.org} placeholder="Optional" onChange={e=>{this.setState({org:e.target.value})}} />
			</Col>
			</FormGroup>

			<FormGroup check row>
			<Col sm={{size:10, offset:2}}>
			<Button disabled={!this.is_valid()} outline={!this.is_valid()} color={this.is_valid()?"success":"secondary"} type="submit">Sign Up</Button>
			{" "}
			<Button outline={true} color="danger" onClick={this.props.done} >Cancel</Button>
			</Col>
			</FormGroup>


			</Form>
		</div>
	}
};
