
import React,{Component} from 'react';
import {
	ListGroup,
	ListGroupItem,
	Button,
	Spinner
} from 'reactstrap';
import axios from 'axios';
import BaseballCard from './baseballcard';
import Netmap from './netmap';
import {select} from 'd3';
import {forceSimulation, forceLink, forceManyBody, forceCenter} from 'd3';

const ipInt = require ('ip-to-int');

export default class CollectorReports extends Component {

	// Props
	//   sess, a string, the session key
	//   assettag, a string, the asset tag (short auth text) of a collector

	state = {
		data:[],
		detail:[],
		listby: "",
		spinner: false,
		show: ""
	};

	componentDidMount() {
		axios.post ("/admin/srcdstmacs", {
			sess: this.props.sess,
			assettag: this.props.assettag
		})
		.then (r=> {
			// had to change this code because appliance version 1113 introduced
			// a breaking change in the javascript format that wraps the data array in
			// an "assets" tag. But we have to retain support for older versions.
		
			var d = r.data == null
				? []
				: typeof (r.data.assets) !== 'undefined'
					?  r.data.assets
					: r.data;
			//const d = (r.data !== null) ? r.data.assets : [];
			if (!d)
				d = []
			var a = Array(r.data.length).fill(false)
			this.setState ({data:d, detail:a})
		})
		.catch (e=> {
			this.setState ({data:[],detail:{}})
		})
	}


	last24hours=()=>{
		this.setState ({spinner:true})
		axios.post ("/admin/aggreport", {
			sess: this.props.sess,
			assettag: this.props.assettag,
			starttime: -720,
			interval: 0
		})
		.then (r=>{
			const d = r.data == null
				? []
				: typeof (r.data.assets) !== 'undefined'
					? r.data.assets
					: r.data;
			//const d = (r.data !== null) ? r.data : [];
			var a = Array(d.length).fill(false)
			this.setState ({spinner:false, data:d, detail:a})
		})
		.catch (e=>{
			this.setState ({spinner:false,data:[],detail:{}})
			alert (e)
		})
	}

	bbb = (m,ix) => {
		if ((parseInt (m["mac"].substring(0,2),16) & 1) === 0) {
			return <ListGroupItem className="blush" key={ix}><BaseballCard data={m} listby={this.state.listby} /></ListGroupItem>
		}
		else
			return null;
	}

	togglelistby = () => {
		this.setState ({ listby: this.state.listby === "ip" ? "" : "ip" })
	}

	/*
	netmap=()=>{
		var n = {}
		var nodes = []
		var links = []
		this.state.data.forEach ((m,ix)=>{
			nodes.push ({id:ix+1, name:m.mac})
			n[m.mac] = ix+1
		})
		this.state.data.forEach ((m,ix)=>{
			const t = m.destmacs ? m.destmacs : []
			t.forEach (dm=>{
				const o = n[dm]
				if (o !== undefined)
					links.push ({source:ix+1, target:n[dm]})
			})
		})
		const dataset = { nodes:nodes, links:links }
		this.shownetmap (dataset)
	}

	shownetmap = dataset => {
		const svg = this.node

		select (svg)
			.selectAll ("svg > *")
			.remove()

		var link = select (svg)
			.selectAll ("line")
			.data (dataset.links)
			.enter()
			.append ("line")
			.style ("stroke", "#aaa")

		var node = select (svg)
			.selectAll ("circle")
			.data (dataset.nodes)
			.enter()
			.append ("circle")
			.attr ("r", 10)
			.style ("fill", "#69b3a2")

		var sim = forceSimulation (dataset.nodes)
			.force ("link", forceLink()
					.id (d=>d.id)
					.links (dataset.links)
				   )
			.force ("charge", forceManyBody().strength(-400))
			.force ("center", forceCenter (500, 250))
			.on ("end", ticked)
			
			function ticked(){
			    link
					.attr("x1", d=>d.source.x)
					.attr("y1", d=>d.source.y)
					.attr("x2", d=>d.target.x)
					.attr("y2", d=>d.target.y)

				node
					.attr("cx", d=>d.x+6)
					.attr("cy", d=>d.y-6)
			}
	}
	*/


	render() {
		return <div>

			<div>
				<Button color="link" style={{fontSize:"0.8em"}} onClick={this.togglelistby}>
				{ this.state.listby === "ip" ? "List by Ethernet address" : "List by IP address" }
				</Button>
			</div>
			<div>
				<Button color="link" style={{fontSize:"0.8em"}} onClick={this.last24hours}>Last 12 hours</Button>
				<Spinner color="danger" style={{visibility: this.state.spinner ? "visible" : "hidden"}} />
			</div>
			<div>
				<Button color="link" style={{fontSize:"0.8em"}} onClick={()=>{this.setState({show:"list"})}}>List</Button>
				<Button color="link" style={{fontSize:"0.8em"}} onClick={()=>{this.setState({show:"map"})}}>Map</Button>
			</div>


			{ this.state.show === "map"
				? <Netmap dataset={this.state.data} />
				: <ListGroup>
					<ListGroupItem>{this.props.assettag}
						<div style={{fontSize:"0.8em",color:"gray"}}>Devices:{this.state.data.length}</div>
					</ListGroupItem>
					{this.state.data.map (this.bbb)}
					</ListGroup>
			}

			</div>
	}
};

const styles = {
	ip: {
		fontSize:"0.8em"
	}
};
