
import React,{Component} from 'react'
import {
	Button
} from 'reactstrap';
import AssetInventory from './assetinventory';
import FlowReport from './flowreport';
import {GState, Superpowers} from './state';
import {observer} from 'mobx-react';

/*******
Reports
*******/

export const Reports = observer (class Reports extends Component {

	state = {
		report: ""
	}

	render() {
		return (this.state.report === "assetinventory")
			? <AssetInventory />
			: (this.state.report === "flows")
			? <FlowReport />
			: <div>
				<h3 className="pageheader">Reports</h3>

				<div style={{height:"1em"}} />

				<div><Button color="link" size="sm" onClick={e=>{this.setState({report:"assetinventory"})}} >Asset Inventory</Button></div>
				<div><Button color="link" size="sm" onClick={e=>{this.setState({report:"flows"})}} >Network Flows</Button></div>
				</div>
	}


});


