
import React,{Component} from 'react';
import axios from 'axios';
import {
	Button,
	Spinner,
	ListGroup,
	ListGroupItem,
	Row,
	Col,
	Progress,
	Input,
	DropdownItem,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	InputGroup,
	InputGroupButtonDropdown,
	Label,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import {
} from 'availity-reactstrap-validation';
import CaptureSetFiles from './capturesetfiles';
import CaptureSetReports from './capturesetreports';
import NewCaptureSet from './capturesetnew';
import {GState} from './state';
import ReactFileReader from 'react-file-reader';


class Uploader extends Component {
	// Props
	//   cancel, a function that tells the caller we're done

	state = {
		capsets: [],
		location: "",
		locationserial: "",
		fileobject: null,
		progress: 0
	}

	componentDidMount() {
		axios.post ("/admin/capsetlist", {
			sess: GState.session
		})
		.then (r=> {
			this.setState ({capsets: r.data})
		})
		.catch (e=> {
			alert (e)
		})
	}


	locwithpicks=()=>{
		const plch = "Enter a new location, or choose from the list"
		return <div>
			<InputGroup>
			<InputGroupButtonDropdown size="sm" addonType="prepend" isOpen={this.state.existinglocationtoggle} toggle={e=>{this.setState({existinglocationtoggle:!this.state.existinglocationtoggle})}} >
				<DropdownToggle caret>
				Locations
				</DropdownToggle>
				<DropdownMenu>
					{ this.state.capsets.map ((m,ix)=><DropdownItem
						key={ix}
						onClick={()=>{this.setState({location:m.Name,locationserial:m.Shortname})}}
						>{m.Name}
						</DropdownItem>)
					}
				</DropdownMenu>
			</InputGroupButtonDropdown>
			<Input size="sm" value={this.state.location} placeholder={plch} onChange={e=>{this.setState({location:e.target.value,locationserial:""})}} />
			</InputGroup>

			<div style={{height:"0.2em"}} />
			<div style={styles.smallitalics}>
			Locations help you track your network over time.
			If you're not sure what to enter here, leave it blank. You can change it later.
			</div>
		</div>
	}
	locwithoutpicks=()=>{
		const plch = "Location where the capture was taken"
		return <div>
			<InputGroup>
			<Input size="sm" value={this.state.location} placeholder={plch} onChange={e=>{this.setState({location:e.target.value,locationserial:""})}} />
			</InputGroup>

			<div style={{height:"0.2em"}} />
			<div style={styles.smallitalics}>
			Locations help you track your network over time.
			If you're not sure what to enter here, leave it blank. You can change it later.
			</div>
		</div>
	}

	go = () => {
		var fd = new FormData()
		fd.append ("file", this.state.fileobject, this.state.fileobject.name)
		fd.append ("sess", GState.session)
		fd.append ("chosencapset", this.state.locationserial)
		fd.append ("createcapset", this.state.location)

		axios.post ("/admin/uploadpcap", fd, {
			onUploadProgress: p => {
				const g = Math.floor ((p.loaded / p.total) * 100.0)
				this.setState ({progress: g})
			}
		})
		.then (r=>{
			alert ("ok")
		})
		.catch (r=>{
			const d = (r.response && r.response.data) ? (r.response.data) : "A server error occurred"
			alert (d)
		})
	}


	render() {
		return <div>

				<div style={{height:"1em"}} />

				<div className="formpanel">
				<ReactFileReader handleFiles={files=>{this.setState({fileobject:files[0]})}} fileTypes="*.*">
				<Button color="primary" size="sm">Choose file to upload</Button>
				<p style={styles.filename} >{this.state.fileobject ? this.state.fileobject.name : ""}</p>
				</ReactFileReader >
				<Progress color="primary" animated value={this.state.progress} >{ this.state.progress ? (""+this.state.progress+"%") : ""}</Progress>
				</div>

				<div style={{height:"1em"}} />

				<div className="formpanel">
				<span style={{color:"steelblue"}}>Location</span>
				{ this.state.capsets.length > 0 ? this.locwithpicks() : this.locwithoutpicks() }
				</div>

				<div style={{height:"1em"}} />

				<div className="formpanel">
				<Button size="sm" disabled={this.state.fileobject===null} color="success" outline={this.state.fileobject===null} onClick={this.go} >Ok</Button>
				{" "}
				<Button size="sm" onClick={e=>{this.props.cancel()}} >Cancel</Button>
				</div>

				{/*
				<div onClick={()=>{this.setState({editlocation:true})}} >
				<Input className="disa" value={this.state.location} disabled />
				</div>
				*/}



			</div>
	}
}



class XXXUploader extends Component {

	// Props
	//   cancel, a function that tells the caller we're done

	state = {
		fileobject: null,
		progress: 0,
		pickset: 0,
		capsets: [],
		createcapset: "",
		chosencapset: "",
		picklist: false
	}

	componentDidMount() {
		axios.post ("/admin/capsetlist", {
			sess: GState.session
		})
		.then (r=> {
			this.setState ({capsets: r.data})
		})
		.catch (e=> {
			alert (e)
		})
	}

	go = () => {
		var fd = new FormData()
		fd.append ("file", this.state.fileobject, this.state.fileobject.name)
		fd.append ("sess", GState.session)
		fd.append ("chosencapset", this.state.chosencapset)
		fd.append ("createcapset", this.state.createcapset)

		axios.post ("/admin/uploadpcap", fd, {
			onUploadProgress: p => {
				const g = Math.floor ((p.loaded / p.total) * 100.0)
				this.setState ({progress: g})
			}
		})
		.then (r=>{
			alert ("ok")
		})
		.catch (r=>{
			const d = (r.response && r.response.data) ? (r.response.data) : "A server error occurred"
			alert (d)
		})
	}


	picknew = ()=> {
		return <Input value={this.state.createcapset} onChange={e=>{this.setState({createcapset:e.target.value,chosencapset:""})}}  placeholder="New capture-set name"/>
	}

	pickexisting=()=>{
		const items = this.state.capsets.map ((m,ix)=>
				<DropdownItem key={ix} onClick={e=>{this.setState({createcapset:"",chosencapset:m.Shortname})}} >
				<span style={this.state.chosencapset===m.Shortname ? styles.picked : null}>{m.Name}</span>
				</DropdownItem>)

		return <div>
				<Dropdown isOpen={this.state.picklist} toggle={e=>{this.setState({picklist:!this.state.picklist})}} >
				<DropdownToggle caret tag="span" style={{color:"green",cursor:"pointer"}}>
				Choose Capture-set
				</DropdownToggle>
				<DropdownMenu>
				{items}
				</DropdownMenu>
				</Dropdown>
			</div>
	}



	render() {
		return <div>
				<ReactFileReader handleFiles={files=>{this.setState({fileobject:files[0]})}} fileTypes="*.*">
				<Button color="primary" size="sm">Choose file to upload</Button>
				<p style={styles.filename} >{this.state.fileobject ? this.state.fileobject.name : ""}</p>
				</ReactFileReader >
				<Progress color="primary" animated value={this.state.progress} >{ this.state.progress ? (""+this.state.progress+"%") : ""}</Progress>
				<div style={{height:"1em"}} />


				<div>
				<div><span style={styles.lighttext}>Specify a new or existing capture-set if you have more than one capture from the same location</span></div>
				<div><span style={styles.lighttext}>Leave it blank if you only have one capture or you're not sure.</span></div>
				Capture set:
				<Button color="link" size="sm" onClick={e=>{this.setState({pickset:1})}} >Create</Button>
				<Button color="link" size="sm" onClick={e=>{this.setState({pickset:2})}} >Choose Existing</Button>
				</div>

				{ this.state.pickset === 1
					? this.picknew()
					: this.state.pickset === 2
					? this.pickexisting()
					: null
				}

				<div style={{height:"1em"}} />

				<Button size="sm" disabled={this.state.fileobject===null} color="success" outline={this.state.fileobject===null} onClick={this.go} >Ok</Button>
				{" "}
				<Button size="sm" onClick={e=>{this.props.cancel()}} >Cancel</Button>
			</div>
	}
}


/*************
default class
*************/

export default class extends Component {

	state = {
		show: "",
		capsets: []
	}

	componentDidMount() {
		this.refresh()
	}

	refresh=()=>{
		axios.post ("/admin/capsetlist", {
			sess: GState.session
		})
		.then (r=>{
			console.log (r.data)
			this.setState ({capsets:r.data})
		})
		.catch (e=>{
			alert (e)
		})
	}

	render() {
		return this.state.show === "upload"
			? <div>
				<h3 className="pageheader">Network Captures: Upload File</h3>
				<Button color="link" size="sm" onClick={e=>{this.setState({show:""})}} >Back to list</Button>
				<Uploader cancel={()=>{this.setState({show:""}); this.refresh()}} />
				</div>
			: <div>
				<h3 className="pageheader">Network Captures</h3>
				<Button color="link" size="sm" onClick={e=>{this.setState({show:"upload"})}} >Upload capture file</Button>
					<ListGroup>
					{ this.state.capsets.map ((m,ix)=><ListGroupItem
							key={ix}
							className="blush"
							>{m.Name}
							</ListGroupItem>)
					}
					</ListGroup>
				</div>

	}

	/*
	xxxrender() {

		return <div>
			<h3 className="pageheader">Captures</h3>

			<p>
			<Button color="link" style={this.state.show==="upload"?styles.colorlink:null} size="sm" onClick={e=>{this.setState({show:"upload"})}} >Upload capture file</Button>
			{" "}
			<Button color="link" style={this.state.show==="list"?styles.colorlink:null} size="sm" onClick={e=>{this.setState({show:"list"})}} >Capture-set List</Button>
			</p>

			<div style={{height:"2em"}} />

			{ this.state.show === "upload"
				? <Uploader cancel={e=>{this.setState({show:""})}} />
				: this.state.show === "list"
				? <div>list</div>
				: null
			}

			</div>
	}
	*/
}


const styles = {
	colorlink: {
		color: "white",
		backgroundColor: "steelblue"
	},
	filename: {
		fontSize: "small",
		color: "steelblue"
	},
	lighttext: {
		color: "gray",
		fontSize: "0.7em",
		fontStyle: "italic"
	},
	lighttitle: {
		color: "black",
		fontSize: "0.7em",
		fontStyle: "italic"
	},
	picked: {
		color: "green"
	},
	activefield: {
		backgroundColor: "lightgray",
		minWidth: "40em"
	},
	smallitalics: {
		fontSize: "0.7em",
		color: "gray",
		fontStyle: "italic"
	}

}

