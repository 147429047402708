
import React,{Component} from 'react'
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Progress,
	ListGroup,
	ListGroupItem
} from 'reactstrap';
import ReactFileReader from 'react-file-reader';

import axios from 'axios';




class PickFile extends Component {

	// Props:
	//   sess, a string containing a session key
	//   open, a boolean value
	//   ok, a function taking no args

	state = {
		fileobject: null,
		progress: 0
	}

	componentDidUpdate (prevprops) {
		if (this.props.open && !prevprops.open) {
			this.setState ({fileobject: null, progress:0})
		}
	}

	hf = files => {
		this.setState ({fileobject: files[0]})
	}

	go = e => {
		var fd = new FormData()
		fd.append ("file", this.state.fileobject, this.state.fileobject.name)
		fd.append ("session", this.props.sess)

		axios.post ("/admin/postfirmware", fd, {
			onUploadProgress: p => {
				const g = Math.floor ((p.loaded / p.total) * 100.0)
				this.setState ({progress: g})
			}
		})
		.then (r=>{
			this.props.ok()
		})
		.catch (r=>{
			alert ("error: " + r)
		})
	}

	render() {
		return <Modal isOpen={this.props.open}>
			<ModalHeader style={styles.modalheader} >
			Upload Collector Firmware-update
			</ModalHeader>
			<ModalBody>
				<ReactFileReader handleFiles={this.hf} fileTypes="*.*">
				<Button color="primary" size="small">Choose file to upload</Button>
				<p style={styles.filename} >{this.state.fileobject ? this.state.fileobject.name : ""}</p>
				</ReactFileReader >
				<Progress color="primary" animated value={this.state.progress} >{ this.state.progress ? (""+this.state.progress+"%") : ""}</Progress>
			</ModalBody>
			<ModalFooter>
				<Button disabled={this.state.fileobject===null} color="primary" outline={this.state.fileobject===null} onClick={this.go} >Ok</Button>
				<Button onClick={e=>{this.props.ok()}} >Cancel</Button>
			</ModalFooter>
			</Modal>
	}

}


export default class Firmware extends Component {

	// Props:
	//   sess, a string containing a session key

	state = {
		upload: false,
		files: []
	};

	componentDidMount() {
		this.refresh()
	}

	refresh = ()=> {
		axios.post ("/admin/firmwarelist", {
			"sess": this.props.sess
		})
		.then (r=>{
			this.setState ({files:r.data})
		})
		.catch (r=>{
			alert ("error:" + r)
		})
	}


	render() {

		const files = this.state.files.map ((m,ix)=><ListGroupItem className="blush" key={ix}>
				<p>{m.Name}</p>
				<p>{m.Size}</p>
				</ListGroupItem>)

		return <div>
			<h3 className="pageheader">Firmware Updates</h3>

			<Button color="link" size="small" onClick={e=>{this.setState({upload:true})}}>Upload Collector Firmware</Button>
			<p/>

			<ListGroup>
			{files}
			</ListGroup>

			<PickFile sess={this.props.sess} open={this.state.upload} ok={()=>{this.setState({upload:false});this.refresh()}}/>

			</div>
	}
};


const styles = {
	filename: {
		fontSize: "small",
		color: "steelblue"
	},
	modalheader: {
		backgroundColor: "steelblue",
		color: "white"
	}
};

