
import React,{Component} from 'react';
import {GState} from './state';
import {
	Label,
	Input,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';
import axios from 'axios';



export default class _cp extends Component {

	// Props:
	//   picked, a function taking an array of serial strings representing the collectors
	//   and captures we've selected

	state = {
		list: [],
		pickopen: false,
		picked: -1
	}

	componentDidMount() {
		axios.post ("/admin/elastic_collcapsetlist", {
			sess: GState.session
		})
		.then (r=>{
			this.setState ({list:r.data})
		})
		.catch (e=>{
			alert (e)
		})
	}

	pick = ix=> {
		var m = this.state.list
		m[ix].picked = ! m[ix].picked
		this.setState ({list:m})
	}


	notify_caller = ()=> {
		this.props.picked ( this.state.picked < 0 ? [] : [this.state.list[this.state.picked].Serial] )
	}

	render() {
		this.notify_caller()

		return <div style={{fontSize:"0.8rem",color:"gray"}}>
			Collector or capture set:
			<Dropdown isOpen={this.state.pickopen} toggle={()=>{this.setState({pickopen:!this.state.pickopen})}} >
				<DropdownToggle caret size="sm" color="info">
				{ this.state.picked < 0 ? "Choose a collector or capture set" : this.state.list[this.state.picked].Name }
				</DropdownToggle>
				<DropdownMenu>
				{ this.state.list.map ((m,ix)=> <DropdownItem key={ix} onClick={e=>{this.setState({picked:ix})}} >
						{m.IsCollector ? "Collector: " : "Capture set: "}
						{m.Name}
						</DropdownItem>)
				}
				</DropdownMenu>
			</Dropdown>
			{/*
			{ this.state.list.map ((m,ix)=> <div key="ix" style={{paddingLeft:"2em", fontSize:"0.8rem",color:"gray"}}>
					<Label type="check">
					<Input type="checkbox" checked={this.state.list[ix].picked} onClick={e=>{this.pick(ix)}} />
					{m.Name}
					</Label>
					</div>)
			}
			*/}
			</div>
	}
}

