import React,{Component} from 'react';
import './App.css';
import {
	ListGroup,
	ListGroupItem,
	Button,
	Spinner,
	CustomInput,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
	Input
} from 'reactstrap';
import CollectorDetail from './collectordetail';
import CollectorSumm from './collectorsumm';
import CollectorReports from './collectorreports';
import CollectorUptime from './collectoruptime';
import CollectorStatusUpdater from './statusupdater';
import {GState,Superpowers} from './state';
import {autorun} from 'mobx';
import axios from 'axios';




/***************
ManualProvision
***************/

class ManualProvision extends Component {

	// Props:
	//   ok, a function that tells the caller we're done.

	state = {
		tag: "",
		tagok: false
	}

	change = e=> {
		const v = e.target.value
		var re = /^[A-Z]{8}$/
		this.setState ({tag:v, tagok: re.test(v)})
	}

	ok = ()=> {
		axios.post ("/admin/manualprovision", {
			sess: GState.session,
			assettag: this.state.tag
		})
		.then (r=>{
			alert ("operation succeeded")
			this.props.ok()
		})
		.catch (e=>{
			alert (e)
		})
	}

	render() {
		return <Modal isOpen={true}>
			<ModalHeader>
				<div style={styles.modalhead} >Manually Provision a Collector</div>
				<div style={styles.modalsubhead} >Use the IOTMonitor mobile app if possible.</div>
			</ModalHeader>
			<ModalBody>
				<Label>Enter the asset tag (eight upper-case letters)</Label>
				<Input value={this.state.tag} onChange={this.change} />
			</ModalBody>
			<ModalFooter>
				<Button color="danger" onClick={this.ok} disabled={!this.state.tagok} outline={!this.state.tagok}  >Ok</Button>
				<Button onClick={this.props.ok} >Cancel</Button>
			</ModalFooter>
			</Modal>
	}
}


/*************
CollectorList
*************/

export default class CollectorList extends Component {

	// Props:
	//   sess, a string representing a session key

	state = {
		lines: [],
		spinner: false,
		showprov: true,
		showunprov: true,
		config: "",
		reports: "",
		uptime: "",
		manualprovision: false
	}

	componentDidMount() {
		// respond immediately to changes made in this instance by observing refreshview,
		// respond periodically to changes made in other instances by refreshing on a timer.
		this.refreshdisposer = autorun(()=>{this.xxx=GState.refreshview; this.refresh()})
		this.interval = setInterval (()=>{GState.refreshview++}, 30000)
	}
	componentWillUnmount() {
		clearInterval (this.interval)
		this.refreshdisposer()
	}

	refresh=()=> {
		this.setState ({spinner:true})
		axios.post ("/admin/collectorlist", {
			sess: GState.session
		})
		.then (r=>{
			this.setState ({lines: r.data, spinner:false})
		})
		.catch (r=>{
			this.setState ({spinner:false})
			// don't alert on errors. This runs on a timer and doesn't touch the session,
			// so we don't want it blowing alerts as the session is expiring.
			//alert ("error: " + r)
		})
	}


	manualprovision=()=> {
		this.setState ({manualprovision:true})
	}


	render() {
		const page = this.state.reports !== ""
			? <div>
				<h3 className="pageheader">Collector Reports</h3>
				<Button color="link" onClick={e=>{this.setState({reports:""})}}>Back to List</Button>
				<CollectorReports sess={this.props.sess} assettag={this.state.reports} />
				</div>
			: this.state.config !== ""
			? <div>
				<h3 className="pageheader">Collector Config</h3>
				<Button color="link" onClick={e=>{this.setState({config:""})}}>Back to List</Button>
				<CollectorDetail assettag={this.state.config} />
				</div>
			: this.state.uptime !== ""
			? <div>
				<h3 className="pageheader">Collector Uptime</h3>
				<Button color="link" onClick={e=>{this.setState({uptime:""})}}>Back to List</Button>
				<CollectorUptime sess={this.props.sess} assettag={this.state.uptime} />
				</div>
			: <div>
				<h3 className="pageheader" >Collectors</h3>

				<div>
				{/* <Button color="link" size="small" onClick={this.refresh}>Refresh List</Button> */}
					<Spinner color="warning" size="sm" style={{visibility: this.state.spinner ? "visible" : "hidden"}} />
					<Button color="link" size="small" onClick={this.manualprovision}>Manual Provision</Button>
				</div>

				{ this.state.manualprovision ? <ManualProvision ok={()=>{this.setState({manualprovision:false})}} /> : null }
				<Superpowers>
					<CustomInput label="Show unprovisioned" checked={this.state.showunprov} type="switch" id="showunprovisioned" onChange={e=>{this.setState({showunprov:e.target.checked})}} />
					<CustomInput label="Show provisioned" checked={this.state.showprov} type="switch" id="showprovisioned" onChange={e=>{this.setState({showprov:e.target.checked})}} />
				</Superpowers>
				<ListGroup>
				{
					this.state.lines.map ((m,ix)=>{
						const owned = m.Fname.Valid || m.Lname.Valid;
						return ((this.state.showprov && owned) ||
						(this.state.showunprov && !owned))
						? <ListGroupItem className="blush" key={ix}>
							<CollectorSumm
								sess={this.props.sess}
								data={m}
								refresh={()=>{this.refresh()}}
								reports={()=>{this.setState({reports:m.ShortAuthText})}}
								uptime={()=>{this.setState({uptime:m.ShortAuthText})}}
								config={()=>{this.setState({config:m.ShortAuthText})}} />
							</ListGroupItem>
						: null
						})
				}
				</ListGroup>
				</div>


		return <div>
			<CollectorStatusUpdater />
			{page}
			</div>
	}
}



const styles = {
	installed: {
		textAlign: "right",
		fontSize: "0.7em"
	},
	owned: {
		backgroundColor: "steelblue",
		color: "white"
	},
	unowned: {
		backgroundColor: "aliceblue",
		color: "black"
	},
	getcodebutton: {
		fontSize: "0.7em",
		color: "darkorange"
	},
	mobileheader: {
		fontSize: "1.1em",
		color: "steelblue"
	},
	modalsubhead: {
		fontSize: "0.7em",
		color: "gray"
	}
};

