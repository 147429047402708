
import React,{Component} from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	Progress
} from 'reactstrap';
import axios from 'axios';

export default class EnterpriseEd extends Component {

	state={
		current_version: 0,
		update: false,
		waiting: false,
		progress: 0
	}

	componentDidMount() {
		axios.post ("/admin/enterprisestatus", {
			sess: this.props.sess
		})
		.then (r=>{
			this.setState ({current_version: r.data.current_version})
		})
		.catch (e=>{
			alert (e)
		})
	}

	confirmupdate = ()=> {
		this.setState ({update:true})
	}

	fire=()=> {
		this.setState ({progress:this.state.progress+1})
		if (this.state.progress > 15 && (this.state.progress % 5) < 0.01) {
			axios.post ("/admin/___gimme404___", {
			})
			.catch (e=>{
				if (e.response.status === 404)
					window.location = "/"
			})
		}
		else if (this.state.progress > 100) {
			window.location = "/";
		}
	}

	doupdate=()=>{
		axios.post ("/admin/enterprise/updatetocurrent", {
			sess: this.props.sess
		})
		.then (r=>{
			this.setState ({update:false, waiting:true})
			setInterval (this.fire, 1000)
		})
		.catch (e=>{
			alert (e)
		})
	}



	render() {
		const updatemodal = <Modal isOpen={this.state.update} color="alert" >
			<ModalBody>
				<p className="mb-0">Are you sure?</p>
				<p className="mb-0" style={{fontSize:"0.8em",color:"gray"}}>The version update may take several minutes</p>
			</ModalBody>
			<ModalFooter>
				<Button color="danger" onClick={this.doupdate}>Ok</Button>
				<Button color="secondary" onClick={e=>{this.setState({update:false})}}>Cancel</Button>
			</ModalFooter>
			</Modal>

		const waitingmodal = <Modal isOpen={this.state.waiting} color="alert" >
			<ModalBody>
				<p className="mb-0">Waiting for update to complete...</p>
				<p className="mb-0" style={{fontSize:"0.8em",color:"gray"}}>The version update may take several minutes</p>
				<p/>
				<Progress color="danger" animated value={this.state.progress} >{ this.state.progress ? (""+this.state.progress+"%") : ""}</Progress>
				<p/>
			</ModalBody>
			<ModalFooter>
			</ModalFooter>
			</Modal>

		return <div>
			<h3 className="pageheader">Enterprise Edition</h3>

			{updatemodal}
			{waitingmodal}

			<p>Version currently running: {this.state.current_version}</p>
			<Button color="danger" onClick={this.confirmupdate}>Update to Current</Button>

			</div>
	}
}

