
import React,{Component} from 'react';
import {
	Button,
	Row,
	Col,
	ListGroup,
	ListGroupItem,
	Spinner
} from 'reactstrap';
import BaseballCard from './baseballcard';
import Netmap from './netmap';

import axios from 'axios';

export default class CaptureReport extends Component {

	// Props:
	//   sess, a string containing a session key
	//   file, a string containing a capture file name
	//   ok, a function taking no arguments signalling the caller that we're done here

	state = {
		spinner: false,
		data:[]
	};

	componentDidMount() {
		this.setState ({spinner: true})
		axios.post ("/admin/getcapturereport", {
			sess: this.props.sess,
			filename: this.props.file
		})
		.then (r=> {
			const d = (r.data !== null) ? r.data : [];
			this.setState ({data:d})
		})
		.catch (e=> {
			this.setState ({data:[]})
			alert (e)
		})
		.finally (()=> {
			this.setState ({spinner: false})
		})
	}

	aaa=(m,ix)=>{
		const ips = m["ipaddresses"] ? m["ipaddresses"].map ((m,ix)=> <div key={ix} style={{color:"steelblue",fontSize:"0.9em"}} >{m}</div>) : null;

		const flows = m["flows"] ? m["flows"].map ((m,ix)=> <div key={ix} style={styles.ip} >{m}</div>) : null;

		const dnshosts = m["dnshosts"] ? m["dnshosts"].map ((m,ix)=> <div key={ix} style={styles.ip} >{m}</div>) : null;

		return <ListGroupItem key={ix}>

			<Row>
				<Col>
				<div style={{fontSize:"1.3em", color:"gray", paddingBottom:"0.2em"}} >{m["oui"]}</div>
				<div style={{fontSize:"0.8em", paddingBottom:"0.5em"}} >{m["mac"]}</div>
				{ips}
				</Col>
				<Col>
				{flows}
				</Col>
				<Col>
				{dnshosts}
				</Col>
			</Row>


			</ListGroupItem>
	}

	bbb = (m,ix) => {
		return <ListGroupItem key={ix}><BaseballCard data={m} /></ListGroupItem>
	}

	render() {
		return <div>
			<h3>Capture File Report</h3>
				<Spinner color="warning" size="sm" style={{visibility: this.state.spinner ? "visible" : "hidden"}} />
			<Button color="link" onClick={this.props.ok}>Back to List</Button>



			<div>
				<Button color="link" style={{fontSize:"0.8em"}} onClick={this.togglelistby}>
				{ this.state.listby === "ip" ? "List by Ethernet address" : "List by IP address" }
				</Button>
			</div>
			<div>
				<Button color="link" style={{fontSize:"0.8em"}} onClick={this.last24hours}>Last 12 hours</Button>
				<Spinner color="danger" style={{visibility: this.state.spinner ? "visible" : "hidden"}} />
			</div>
			<div>
				<Button color="link" style={{fontSize:"0.8em"}} onClick={()=>{this.setState({show:"list"})}}>List</Button>
				<Button color="link" style={{fontSize:"0.8em"}} onClick={()=>{this.setState({show:"map"})}}>Map</Button>
			</div>


			{ this.state.show === "map"
				? <Netmap dataset={this.state.data} />
				: <ListGroup>
					<ListGroupItem>{this.props.file}
						<div style={{fontSize:"0.8em",color:"gray"}}>Devices:{this.state.data.length}</div>
					</ListGroupItem>
					{this.state.data.map (this.bbb)}
					</ListGroup>
			}


			</div>
	}

	xxxrender() {
		return <div>
			<h3>Capture File Report</h3>
				<Spinner color="warning" size="sm" style={{visibility: this.state.spinner ? "visible" : "hidden"}} />
			<Button color="link" onClick={this.props.ok}>Back to List</Button>
				<ListGroup>
				<ListGroupItem>
					{this.props.file}
					<div style={{fontSize:"0.8em",color:"gray"}}>Devices:{this.state.data.length}</div>
				</ListGroupItem>
				{this.state.data.map (this.bbb)}
				</ListGroup>
			</div>
	}

}



const styles = {
	ip: {
		fontSize:"0.8em"
	}
};
