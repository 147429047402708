

import React,{Component} from 'react';
import axios from 'axios';
import {
	Button,
	Spinner,
	FormGroup,
	Label
} from 'reactstrap';
import {
	AvForm,
	AvGroup,
	AvInput,
	AvFeedback
} from 'availity-reactstrap-validation';

/*************
NewCaptureSet
*************/

export default class NewCaptureSet extends Component {

	// Props:
	//   sess, a string containing a session key
	//   ok, a function taking a string, the name of a newly-created capture-set
	//   cancel, a function taking no arguments

	state = {
		name: "",
		desc: "",
		spinner: false
	}

	submit=()=> {
		this.setState ({spinner:true})
		axios.post ("/admin/newcaptureset", {
			sess: this.props.sess,
			name: this.state.name,
			desc: this.state.desc
		})
		.then (r=>{
			this.setState ({spinner:false})
			this.props.ok (this.state.name)
		})
		.catch (e=>{
			this.setState ({spinner:false})
			alert (e)
		})
	}

	// TODO, theres a bug in here. You have to click the cancel button TWICE to get out of the form.
	// For some reason, the first click triggers a form validation.

	render() {
		return <div style={{border:"1px lightgray solid",margin:"1em",padding:"1em"}}>
			<h4>New Capture Set</h4>
			<Spinner color="primary" style={{visibility: this.state.spinner ? "visible" : "hidden"}} />

			<AvForm onValidSubmit={this.submit}>

			<AvGroup>
			<Label for="name" >Descriptive name</Label>
			<AvInput autoFocus name="name" id="name" required value={this.state.name} onChange={e=>{this.setState({name:e.target.value})}} />
			<AvFeedback>Required</AvFeedback>
			</AvGroup>

			<AvGroup>
			<Label for="desc" >Detailed description (optional)</Label>
			<AvInput name="desc" id="desc" value={this.state.desc} type="textarea" onChange={e=>{this.setState({desc:e.target.value})}} />
			</AvGroup>

			<FormGroup>
			<Button color="primary" type="submit">Ok</Button>
			{" "}
			<Button color="primary" onClick={this.props.cancel} >Cancel</Button>
			</FormGroup>
			</AvForm>
			</div>
	}
};

