
import React,{Component} from 'react';
import {
	Button,
	Label,
	FormGroup
} from 'reactstrap';
import {
	AvForm,
	AvGroup,
	AvInput
} from 'availity-reactstrap-validation';
import axios from 'axios';



export default class Forgot extends Component {

	// Props:
	//   done, a function taking no args that tells the caller we're done here.

	state = {
		phase: 1,
	}

	sendcode = e=> {
		e.preventDefault();

		if (this.state.phase === 1) {
			axios.post ("/admin/requestreset", {
				email: this.state.email
			})
			.then (r=>{
				this.setState ({phase:2})
			})
			.catch (r=>{
				alert ("Unknown account, try again")
			})
		}
		else if (this.state.phase === 2) {
			axios.post ("/admin/requestreset2", {
				email: this.state.email,
				code: this.state.code
			})
			.then (r=>{
				this.setState ({phase:3})
			})
			.catch (r=>{
				alert ("Invalid phone code, try again")
			})
		}
		else if (this.state.phase ===3 ) {
			if (this.state.psw !== this.state.psw2)
				alert ("Passwords don't match")
			else if (!this.state.psw)
				alert ("Please fill in the password fields")
			else {
				axios.post ("/admin/requestreset3", {
					email: this.state.email,
					code: this.state.code,
					psw: this.state.psw
				})
				.then (r=>{
					alert ("Your password has been reset")
					this.props.done()
				})
				.catch (r=> alert ("Failed to reset password") )
			}
		}

	}

	render() {
		const btntext = this.state.phase === 1
			? "Send security code to your phone"
			: this.state.phase === 2
			? "Submit"
			: "Change password"
			;

		return <div>
				<Button color="link" style={{fontSize:"0.65em"}} onClick={this.props.done} >Back to the Sign-in page</Button>

				<div style={{color:"gray"}}>
					<div style={{marginLeft:"1em"}}>

					<h4>Reset Password</h4>

						<AvForm onSubmit={this.sendcode}>
							<AvGroup>
							<Label for="email" >Email Address</Label>
							<AvInput autoFocus name="email" id="email" required value={this.state.email} onChange={e=>{this.setState({email:e.target.value})}} />
							</AvGroup>

							{ this.state.phase >= 2
								?  <AvGroup>
									<Label for="code" >Security code from your phone</Label>
									<AvInput autoFocus name="code" id="code" required value={this.state.code} onChange={e=>{this.setState({code:e.target.value})}} />
									</AvGroup>
								: null
							}

							{ this.state.phase >= 3
								?  <AvGroup>
									<Label for="psw">New Password</Label>
									<AvInput type="password" name="psw" id="psw" required value={this.state.psw} onChange={e=>{this.setState({psw:e.target.value})}} />
									</AvGroup>
								: null
							}
							{ this.state.phase >= 3
								?  <AvGroup>
									<Label for="psw">New Password (again)</Label>
									<AvInput type="password" name="psw2" id="psw2" required value={this.state.psw2} onChange={e=>{this.setState({psw2:e.target.value})}} />
									</AvGroup>
								: null
							}

							<FormGroup>
							<Button color="primary" type="submit">{btntext}</Button>
							</FormGroup>

						</AvForm>

					</div>
					</div>


			</div>
	}


}


