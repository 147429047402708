
import React,{Component} from 'react';
import {
	Modal,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Button
} from 'reactstrap';
import axios from 'axios';

export default class SessionWatchdog extends Component {

	// Props
	//   sess, a string containing a session key
	//   signout, a function taking no args that signs out the user session

	state = {
		show: false
	}

	timer = null;

	componentDidMount() {
		this.timer = setTimeout (this.fire, 60000)
	}

	componentWillUnmount() {
		if (this.timer !== null) {
			clearTimeout (this.timer);
			this.timer = null;
		}
	}

	fire=()=> {
		axios.post ("/admin/checksession", {
			sess: this.props.sess
		})
		.then (r=>{
			if (r.data.expiresin === 0)
				this.props.signout()
			else if (r.data.expiresin < 60)
				this.setState ({show:true})
			else
				this.setState ({show:false})
		})
		.catch (e=>{
			// This is a no-op. We don't want server errors to be logging people off
		})
		.finally (()=>{
			this.timer = setTimeout (this.fire, 60000)
		})

	}

	touch=()=> {
		this.setState ({show:false})
		axios.post ("/admin/touchsession", {
			sess: this.props.sess
		})
		.catch (e=>{
			// handle the edge case where someone clicks "stay logged in"
			// after the session times out and before our next timer checks it
			this.props.signout()
		})
	}

	render() {
		return <Modal isOpen={this.state.show}>
			<ModalHeader>
			</ModalHeader>

			<ModalBody>
			<p>Your session will expire soon...</p>
			</ModalBody>

			<ModalFooter>
			<Button color="primary" onClick={this.touch} >Stay Logged In</Button>
			</ModalFooter>

			</Modal>
	}
}

