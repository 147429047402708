
import React,{Component} from 'react';
import {select} from 'd3';
import {forceSimulation, forceLink, forceManyBody, forceCenter, forceX, forceY, event, drag} from 'd3';
import BaseballCard from './baseballcard';
import {
	Input
} from 'reactstrap';





export default class NetMap extends Component {


	// Props
	//   dataset

	state = {
		cooked: [],
		pinhead: undefined,
		strength: -50
	}

	height=1000;
	width=1000;
	radius=6;

	componentDidMount() {
		var n = {}
		var nodes = []
		var links = []
		this.props.dataset.forEach ((m,ix)=>{
			nodes.push ({id:ix, name:m.mac, detail:m})
			n[m.mac] = ix
		})
		this.props.dataset.forEach ((m,ix)=>{
			const t = m.destmacs ? m.destmacs : []
			t.forEach (dm=>{
				const o = n[dm]
				if (o !== undefined) {
					links.push ({source:ix, target:n[dm]})
				}
			})
		})
		const dataset = { nodes:nodes, links:links }
		this.setState ({cooked:dataset})
		//this.showChart (dataset)
	}


	componentDidUpdate (p, s) {
		this.showChart();
	}

	showChart = () => {
		const data = this.state.cooked;
		const svg = this.node

		select (svg)
			.selectAll ("svg > *")
			.remove()

		select (svg)
			.attr ("viewBox", [-this.width/2, -this.height/2, this.width, this.height])

		var simulation = forceSimulation (data.nodes)
			.force ("link", forceLink (data.links).id (d=>d.id))
			.force ("charge", forceManyBody().strength(this.state.strength))
			.force ("x", forceX())
			.force ("y", forceY())

		var link = select (svg)
			.append ("g")
			.attr ("stroke", "#999")
			.attr ("stroke-opacity", "0.6")
			.selectAll ("line")
			.data (data.links)
			.join ("line")
			.attr ("stroke-width", d=>Math.sqrt(d.ix))

		var node = select (svg)
			.append ("g")
			.attr ("stroke", "#fff")
			.attr ("stroke-width", 1.5)
			.selectAll ("circle")
			.data (data.nodes)
			.join ("circle")
			.attr ("r", this.radius)
			.attr ("fill", "red")
			.call (this.drag(simulation))
			.on ("click", d=>{ this.setState ({pinhead:d.detail}) })

		simulation.on ("tick", ticked)
			
		function ticked(){
		    link
				.attr("x1", d=>d.source.x)
				.attr("y1", d=>d.source.y)
				.attr("x2", d=>d.target.x)
				.attr("y2", d=>d.target.y)

			node
				.attr("cx", d=>d.x+6)
				.attr("cy", d=>d.y-6)
		}
	}




	drag = simulation => {
		function dragstarted(d) {
			if (!event.active)
				simulation.alphaTarget(0.3).restart();
			d.fx = d.x;
			d.fy = d.y;
		}
		    
		function dragged(d) {
			d.fx = event.x;
			d.fy = event.y;
		}
			  
		function dragended(d) {
			if (!event.active)
				simulation.alphaTarget(0);
			d.fx = null;
			d.fy = null;
		}

		return drag()
			.on("start", dragstarted)
			.on("drag", dragged)
			.on("end", dragended);
	}


	setstrength = e => {
		this.setState ({strength: e.target.value})
	}


	render() {
		return <div>
			<p>
			<Input type="range" className="custom-range" max={-5} min={-100} value={this.state.strength} onChange={this.setstrength} />
			</p>

			<div style={{border:"1px solid black",margin:"1em"}}>
			<svg ref={n=> this.node=n} width={this.width} height={this.height} />
			</div>
			<div className="footer" style={{border:"1px solid green",margin:"1em"}}>
			{ this.state.pinhead !== undefined ? <BaseballCard data={this.state.pinhead} /> : null }
			</div>
			</div>
	}

}





