
import React,{Component} from 'react'
import {
	Button,
	Spinner,
	ListGroup,
	ListGroupItem,
	UncontrolledPopover,
	PopoverHeader,
	PopoverBody,
	Row,
	Col
} from 'reactstrap'
import Timepicker from './timepicker'
import CollectorPicker from './collectorpicker'
import {GState} from './state'
import axios from 'axios'
import NotificationSystem from 'react-notification-system';



export default class _fl extends Component {


	state = {
		assets: [],
		spinner: false
	}

	// These items can't be in state, even though they are set by the TimePicker component.
	// Because TimePicker calls us while performing a state change, we'd get an infinite
	// regress.
	starttime = new Date()
	endtime = new Date()
	data_location = []

	getreport=()=> {
		if (this.data_location.length < 1) {
			this.notes.current.addNotification ({message:"Choose a collector or capture set", level:"error", position:"tl"})
			return
		}

		// Note that we're calling the assetinventory report, but with a hardcoded field list.
		this.setState ({spinner: true})
		axios.post ("/admin/elastic_assetinventory", {
			sess: GState.session,
			collector: this.data_location.join('|'),
			starttime: this.starttime,
			endtime: this.endtime,
			fields: "flows"
		})
		.then (r=>{
			var ary = []
			for (const m in r.data.assets) {
				if ((parseInt (m.substring(0,2),16) & 1) === 0) { // ignore bcast and mcast
					r.data.assets[m]["mac"] = m
					ary.push (r.data.assets[m])
				}
			}
			ary.sort ((a,b)=>{return a["oui"] >= b["oui"]})
			this.setState ({spinner:false, assets: ary})
		})
		.catch (r=>{
			this.setState ({spinner:false, assets: []})
			this.notes.current.addNotification ({message:String(r), level:"error", position:"tl"})
		})
	}

	notes = React.createRef();

	makeflows = (flist) => {
		return flist.map ((m,ix)=><div key={ix}>{m}</div>)
	}

	render() {
		const list = this.state.assets.map ((m,ix)=> <ListGroupItem key={ix}>
				<div style={styles.bigtitle} >{m.oui}</div>
				<div style={styles.smalltitle}>{m.mac}</div>
				{ ("frames" in m) ? <div style={styles.smalltitle}>Frames: {m["frames"]}</div> : null }
				{ ("octets" in m) ? <div style={styles.smalltitle}>Octets: {m["octets"]}</div> : null }
				{ ("role" in m) ? <div style={styles.smalltitle}>Role: {m["role"]}</div> : null }
				{ ("applications" in m) ? <div style={styles.smalltitle}>Applications: {m["applications"].join(", ")}</div> : null }
				{ ("hostnames" in m) ? <div style={styles.smalltitle}>Hostnames: {m["hostnames"].join(", ")}</div> : null }

				{ ("flows" in m) ? <div style={styles.smalltitle}>Flows: {this.makeflows(m["flows"])} </div> : null }

				</ListGroupItem>)

		return <div>
			<NotificationSystem ref={this.notes} />
				<h3 className="pageheader">Reports: Network Flows</h3>

				<Row>
				<Col>
					<div className={`timeme`}>
					<Timepicker picked={(st,et)=>{ this.starttime=st; this.endtime=et}} />
					</div>
				</Col>
				<Col>
					<div className={`timeme`}>
					<CollectorPicker picked={e=>{this.data_location=e}} />
					</div>
				</Col>
				</Row>


				<div style={{height:"1em"}} />


				<Button color="link" size="sm" onClick={this.getreport}>Run report...</Button>
				<Spinner size="sm" color="warning" style={{visibility:this.state.spinner?"visible":"hidden"}} />

				<p style={{fontSize:"0.8em",color:"gray"}}>Assets: {this.state.assets.length}</p>

				<ListGroup>
				{list}
				</ListGroup>

			</div>
	}

}

const styles = {
	bigtitle: {
		fontSize: "1.2em",
		color: "gray"
	},
	smalltitle: {
		fontSize: "0.75em",
		color: "black"
	},
	lesssmalltext: {
		fontSize: "0.95em",
		color: "gray"
	},
	smalltext: {
		fontSize: "0.75em",
		color: "gray"
	},
	smallorange: {
		fontSize: "0.75em",
		color: "darkorange"
	},
	smalllink: {
		fontSize: "0.75em",
		color: "darkorange",
		fontWeight: "bold",
		cursor: "pointer"
	},
	smallgraylink: {
		fontSize: "0.75em",
		color: "darkblue"
	},
	smallalerttext: {
		fontSize: "0.75em",
		color: "red"
	}
};
