
import React,{Component} from 'react';
import {
	ListGroup,
	ListGroupItem
} from 'reactstrap';
import axios from 'axios';

export default class Principals extends Component {

	// Props
	//   sess, a string containing a session key


	state = {
		data: []
	}

	componentDidMount() {
		axios.post ("/admin/principallist", {
			sess: this.props.sess
		})
		.then (r=>{
			console.log (r.data[0].email)
			this.setState ({data: r.data})
		})
		.catch (e=>{
			alert (e)
		})

	}

	render() {
		const principals = this.state.data.map ((m,ix)=><ListGroupItem className="blush" key={ix}>
				<p style={{color:"gray",fontSize:"1.3em"}}>{m.Lname}, {m.Fname}</p>
				<p style={{color:"darkgray",fontSize:"0.8em"}}>{m.Email}</p>
				<p style={{color:"darkgray",fontSize:"0.8em"}}>{m.Phone}</p>
				<p style={{color:"blue",fontSize:"0.8em"}}>{m.Entitlements}</p>
				</ListGroupItem>)

		return <div>
			<h3 className="pageheader">Principals</h3>
			<ListGroup>
			{principals}
			</ListGroup>
			</div>
	}
}

