
import React,{Component} from 'react';
import {observer} from 'mobx-react';
import {
	Row,
	Col,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Spinner,
	ListGroup,
	ListGroupItem,
	Badge,
	UncontrolledPopover,
	PopoverHeader,
	PopoverBody,
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	DropdownItem
} from 'reactstrap';
import axios from 'axios';
import {GState, EE, Superpowers} from './state';




/*************
StatusWatcher
*************/

const StatusWatcher = observer (class _statuswatcher extends Component {
	// Props:
	//   assettag
	//   assigned, a boolean

	render() {

		const id = "statusbadge" + this.props.assettag;

		var t = <Badge id={id} style={{cursor:"pointer"}} >Status Unknown</Badge>
		var b = <Badge>Version Unknown</Badge>

		var statary = []

		const pcs = GState.collector_status;
		if (pcs) {
			const pcsa = pcs[this.props.assettag];
			if (pcsa) {
				const pcsaa = pcsa.Age;
				if (pcsaa !== undefined) {

					statary.push (<p key="age">
					<div style={styles.smalltitle}>status_age</div>
					<div style={styles.smalltext}>{pcsaa}</div>
					</p>)

					t = pcsaa <= 30
						? <Badge id={id} color="success" style={{cursor:"pointer"}} >Active</Badge>
						: pcsaa <= 90
						? <Badge id={id} color="info" style={{cursor:"pointer"}} >Waiting</Badge>
						: pcsaa <= 600
						? <Badge id={id} color="warning" style={{cursor:"pointer"}} >Quiet</Badge>
						: <Badge id={id} color="danger" style={{cursor:"pointer"}} >Inactive</Badge>
				}
				const pcsas = pcsa.Status
				if (pcsas && pcsas.buildnumber) {
					b = <Badge color="info">{pcsas.buildnumber}</Badge>
				}

				if (pcsas) {
					for (const q in pcsas) {
						statary.push (<p key={q}>
						<div style={styles.smalltitle}>{q}</div>
						<div style={styles.smalltext}>{pcsas[q]}</div>
						</p>)
					}
				}

			}
		}

		const po = statary.length > 0
			? <UncontrolledPopover trigger="legacy" target={id}>
			<PopoverHeader style={{color:"steelblue"}}>
			Asset Tag {this.props.assettag}
			</PopoverHeader>
			<PopoverBody>
				{statary}
			</PopoverBody>
			</UncontrolledPopover>
			: null;

		// Do the following check at the very end. We may pick up some legacy status info,
		// which isn't necessarily bad
		if (this.props.assigned)
			t = <Badge id={id} color="info" style={{cursor:"pointer"}} >Assigned</Badge>

		return <span>{t}{" "}{b}{po}</span>
	}
});

/***************
AssignCollector
***************/

class AssignCollector extends Component {

	// Props:
	//   sess, a session key
	//   assettag, a short-auth-text for a collector
	//   ok, a function taking no args telling the caller we're done

	state = {
		error: "",
		eelist: [],
		pickopen: false,
		picked: -1
	};

	componentDidMount() {
		axios.post ( "/admin/getassignmentdata", {
			sess: this.props.sess,
			assettag: this.props.assettag
		})
		.then (r=>{
			this.setState ({eelist: r.data})
		})
		.catch (e=>{
			if (e.response.status === 504) {
				this.setState ({error: "An unknown server error occurred"})
			}
			else {
				var v = e.response.data;
				if (!v || v === "")
					v = "A server error occurred";
				this.setState ({error: v})
			}
		})
	}

	assignit=()=> {
		if (this.state.picked === -1) {
			alert ("Pick an Enterprise Edition")
			return;
		}
		axios.post ("/admin/assigncollector", {
			sess: this.props.sess,
			assettag: this.props.assettag,
			serialtext: this.state.eelist [this.state.picked].Serialtext // this.state.eelist[0].Serialtext // WARNING!!! THERE MAY BE MORE THAN ONE! PICK FROM LIST
		})
		.then (r=>{
			// TODO, need visual feedback 
			this.props.ok()
		})
		.catch (e=>{
			alert (e)
		})
	}


	render() {
		const plist = <div style={{fontSize:"0.8rem",color:"gray"}}>
				<Dropdown isOpen={this.state.pickopen} toggle={()=>{this.setState({pickopen:!this.state.pickopen})}} >
				<DropdownToggle caret size="sm" color="info">
				{ this.state.picked === -1 ? "Select Enterprise Edition" : this.state.eelist[this.state.picked].Name }
				</DropdownToggle>
				<DropdownMenu>
				{ this.state.eelist.map ((m,ix)=> <DropdownItem key={ix} onClick={()=>{this.setState({picked:ix})}} >
						{m.Name}
						</DropdownItem>)
				}
				</DropdownMenu>
			</Dropdown>
			</div>

		const bod = (!this.state.error && this.state.eelist)
			? <div>
				<p className="mb-0" style={{fontSize:"0.8em",color:"gray"}}>Asset tag: {this.props.assettag}</p>
				<div>{plist}</div>
				<p className="mb-0" style={{fontSize:"0.8em",color:"black"}}>
				Warning: this operation will cause the collector to become invisible to this portal
				</p>
				</div>
			: null;

		const footer = this.state.error
			? <ModalFooter><Button size="sm" color="danger" onClick={this.props.ok} >Close</Button></ModalFooter>
			: <ModalFooter>
				<Button size="sm" color="danger" onClick={this.assignit} >Ok</Button>
				<Button size="sm" color="secondary" onClick={this.props.ok} >Cancel</Button>
				</ModalFooter>
			;
		return <Modal isOpen={true}>
			<ModalHeader>
				<p className="mb-0">Assign to Enterprise Edition</p>
			</ModalHeader>
			<ModalBody>
			{bod}
			</ModalBody>
			{footer}
			</Modal>
	}
};



/*************
CollectorSumm
*************/

export default class CollectorSumm extends Component {

	// Props
	//   sess, a string containing a session key
	//   data, an object with collector details
	//   reports, a function we call to invoke report presentation
	//   config, a function we call to invoke a config screen
	//   refresh, a function we call to make the caller refresh the list
	//   uptime, a function we call to make the caller display an uptime page


	state = {
		highlight: false,
		qr: false,
		qrimage: "",
		spinner: false,
		statusdata: {},
		showstatus: false,
		deprovision: false,
		deleteit: false,
		assign: false
	};

	owner = ()=> {
		const fn = this.props.data.Fname;
		const ln = this.props.data.Lname;
		var o = "";
		if (fn && fn.Valid)
			o += fn.String + " ";
		if (ln && ln.Valid)
			o += ln.String;
		return o;
	}

	qr = () => {
		this.setState ({spinner:true})
		axios.post ("/admin/qr", {
			sess: this.props.sess,
			qrtext: this.props.data.ShortAuthText
		})
		.then (r=>{
			this.setState ({qr:true, spinner:false, qrimage: "data:image/png;base64," + r.data})
		})
		.catch (r=>{
			this.setState ({spinner:true})
				/* this leaks platform and version info when the server panics
			const d = (r.status !== 502 && r.response && r.response.data) ? ("\n"+r.response.data) : ""
			alert (r + d)
			*/
			alert (r)
		})
	}

	/* WORKS GREAT BUT NOW OBSOLETE. See StatusWatcher
	getstatus = () => {
		this.setState ({spinner:true})
		axios.post ("/admin/collectorstate", {
			sess: this.props.sess,
			assettag: this.props.data.ShortAuthText
		})
		.then (r=>{
			const age = parseInt (r.data.age, 10)
			r.data["status"] = (age <= 60)
			?  r.data["status"] = <span style={{color:"green"}}>Active</span>
			: (age < 600)
			?  r.data["status"] = <span style={{color:"darkorange"}}>Quiet</span>
			: <span style={{color:"red"}}>Inactive</span>

			this.setState ({statusdata:r.data, showstatus:true, spinner:false})
		})
		.catch (e=>{
			this.setState ({spinner:false})
			alert (e)
		})
	}
	*/

	deprovision=()=> {
		this.setState ({deprovision:false, spinner:true})
		axios.post ("/admin/deprovision", {
			sess: this.props.sess,
			assettag: this.props.data.ShortAuthText
		})
		.then (r=>{
			this.setState ({spinner:false})
			this.props.refresh()
		})
		.catch (r=>{
			this.setState ({spinner:false})
			alert ("error " + r)
		})
	}

	deleteit=()=> {
		this.setState ({deleteit:false, spinner:true})
		axios.post ("/admin/deletecollector", {
			sess: this.props.sess,
			assettag: this.props.data.ShortAuthText
		})
		.then (r=>{
			this.setState ({spinner:false})
			this.props.refresh()
		})
		.catch (r=>{
			this.setState ({spinner:false})
			alert ("error " + r)
		})
	}

	askdeprovision = ()=> {
		this.setState ({deprovision:true})
	}
	askdelete = ()=> {
		this.setState ({deleteit:true})
	}

	render() {
		const m = this.props.data;
		//console.log (this.props.data.ConfigBook)
		const st = m.ShortTitle && m.ShortTitle.Valid && m.ShortTitle.String !== "" ? m.ShortTitle.String : "["+m.ShortAuthText+"]";
		const dt = new Date (m.CreatedAt * 1000)

		const modal = <Modal isOpen={this.state.qr}>
			<ModalBody>
			<div style={{textAlign:"center"}}>
			<img src={this.state.qrimage} alt="QR code"/>
			<p style={{fontSize:"0.85em",fontWeight:"bold",letterSpacing:"0.3em"}}>{this.props.data.ShortAuthText}</p>
			</div>
			</ModalBody>
			<ModalFooter>
			<Button color="link" onClick={e=>{window.print()}}>Print</Button>
			<Button color="link" onClick={e=>{this.setState({qr:false})}}>Close</Button>
			</ModalFooter>
		</Modal>

		var statusprops = []
		for (var p in this.state.statusdata) {
			statusprops.push (<ListGroupItem>{p}{": "}{this.state.statusdata[p]}</ListGroupItem>)
		}

		const statusmodal = <Modal isOpen={this.state.showstatus}>
			<ModalHeader>
			{st}
			</ModalHeader>
			<ModalBody>
			<ListGroup>
			{statusprops}
			</ListGroup>
			</ModalBody>
			<ModalFooter>
			<Button color="link" onClick={e=>{this.setState({showstatus:false})}}>Close</Button>
			</ModalFooter>
		</Modal>

		const deprovisionmodal = <Modal isOpen={this.state.deprovision} color="alert" >
			<ModalBody><p className="mb-0">Are you sure?</p></ModalBody>
			<ModalFooter>
				<Button color="danger" onClick={this.deprovision}>Ok</Button>
				<Button color="secondary" onClick={e=>{this.setState({deprovision:false})}}>Cancel</Button>
			</ModalFooter>
			</Modal>

		const deletemodal = <Modal isOpen={this.state.deleteit} color="alert" >
			<ModalBody>
				<p className="mb-0">Are you sure?</p>
				<p className="mb-0" style={{color:"gray",fontSize:"0.8em"}}>Deleting this collector will purge all its associated stored data<br/>This operation can't be undone</p>
			</ModalBody>
			<ModalFooter>
				<Button color="danger" onClick={this.deleteit}>Ok</Button>
				<Button color="secondary" onClick={e=>{this.setState({deleteit:false})}}>Cancel</Button>
			</ModalFooter>
			</Modal>

		const assignmodal = this.state.assign
				? <AssignCollector sess={this.props.sess} assettag={this.props.data.ShortAuthText} ok={e=>{this.setState({assign:false})}} />
				: null;

		const QrLink = observer (()=> GState.superpowers
			? <Button color="link" style={styles.smalllink} onClick={this.qr} >QR Code</Button>
			: null
		);

		const isAssigned = (()=>{
			// the protocol here is well-defined and a little complicated.
			// A collector is assigned to an enterprise edition if the config book has
			// a portal entry with a non-empty hostname, AND it either lacks a homeportal
			// entry or the homeportal entry is zero/false.
			// This gives us the maximum flex to assign, deassign, and reassign collectors
			// without losing information. In particular, a deassignment is done by adding
			// a true or nonzero homeportal entry in the config, which overrides the portal
			// entry. That way, reassigning can be done by removing or making false the
			// homeportal entry, and retaining whatever config was in the portal entry.
			try {
				const o = JSON.parse (this.props.data.ConfigBook.String)
				return (o.portal.hostname && !o.homeportal)
			}
			catch {
				return false
			}
		}) ();

		return <Row
				onMouseEnter={()=>{this.setState({highlight:true})}}
				onMouseLeave={()=>{this.setState({highlight:false})}}
			>
			<Col>
				{modal}
				{statusmodal}
				{deprovisionmodal}
				{deletemodal}
				{assignmodal}
				<div style={{fontSize:"1.2em", color:"gray", paddingBottom:"0.2em"}} >{st}</div>
				<Spinner color="warning" size="sm" style={{visibility: this.state.spinner ? "visible" : "hidden"}} />

				<div style={{visibility:this.state.highlight?"visible":"hidden"}} >
					<div style={styles.smalltext}>
					{m.Description.Valid ? m.Description.String : null}
					</div>
					<ButtonGroup size="sm">
					<Button color="link" style={styles.smalllink} onClick={this.props.config} >Config</Button>
					<Button color="link" style={styles.smalllink} onClick={this.props.reports} >Reports</Button>
					<QrLink />
					<Superpowers>
						<Button color="link" style={styles.smallgraylink} onClick={this.askdeprovision}>Deprovision</Button>
						<Button color="link" style={styles.smallgraylink} onClick={this.askdelete} >Delete</Button>
					</Superpowers>
					</ButtonGroup>
					<EE reverse>
						<Button size="sm" outline color="info" style={{fontSize:"0.65em"}} onClick={e=>{this.setState({assign:true})}}>Assign to Enterprise Edition</Button>
					</EE>
				</div>

			</Col>
			<Col>
				<div style={{textAlign:"right"}}>
					<div>
					<span style={styles.smalltitle}>Installed: </span>
					<span style={styles.smalltext}>{dt.toLocaleString()}</span>
					{" "}
					<span style={styles.smalltitle}>Mark: </span>
					<span style={styles.smalltext}>{m.InstallerMark}</span>
					</div><div>
					<span style={styles.smalltitle}>Asset tag: </span>
					<span style={styles.smalltext}>{m.ShortAuthText}</span>
					</div><div>
					{ this.owner() === "" 
						? <span style={styles.smallalerttext}>{"Unprovisioned"}</span>
						: <span style={styles.smalltitle}>{"Owner: "}</span>
					}
					<span style={styles.smalltext}>{this.owner()}</span>
					</div><div>

					<EE>         <StatusWatcher assettag={this.props.data.ShortAuthText}  assigned={false} /> </EE>
					<EE reverse> <StatusWatcher assettag={this.props.data.ShortAuthText}  assigned={isAssigned} /> </EE>

					<Button color="link" style={styles.smalllink} onClick={this.props.uptime}>Uptime</Button>
					</div>
				</div>
			</Col>

			</Row>
	}
};


const styles = {
	smalltitle: {
		fontSize: "0.75em",
		color: "black"
	},
	lesssmalltext: {
		fontSize: "0.95em",
		color: "gray"
	},
	smalltext: {
		fontSize: "0.75em",
		color: "gray"
	},
	smalllink: {
		fontSize: "0.75em",
		color: "darkorange"
	},
	smallgraylink: {
		fontSize: "0.75em",
		color: "darkblue"
	},
	smallalerttext: {
		fontSize: "0.75em",
		color: "red"
	}
};
