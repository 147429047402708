
import React,{Component} from 'react'
import Timepicker from './timepicker'
import CollectorPicker from './collectorpicker'
import {
	Button,
	Spinner,
	ListGroup,
	ListGroupItem,
	UncontrolledPopover,
	PopoverHeader,
	PopoverBody,
	Row,
	Col
} from 'reactstrap'
import {GState} from './state'
import axios from 'axios'
import {Multiselect} from 'react-widgets';

import 'react-widgets/dist/css/react-widgets.css';

class FieldPicker extends Component {
	// Props:
	//   notify, a function taking an array of field names


	render() {
		return <div style={{color:"gray",fontSize:"0.8em"}}>
			Additional fields:
			<Multiselect
				data={["applications","role","frames","octets","hostnames","flows"]}
				onChange={v=>{this.props.notify (v)}}
			/>
			</div>
	}
}


export default class _ai extends Component {

	state = {
		assets: [],
		spinner: false
	}

	// These items can't be in state, even though they are set by the TimePicker component.
	// Because TimePicker calls us while performing a state change, we'd get an infinite
	// regress.
	starttime = new Date()
	endtime = new Date()
	data_location = []
	fields = []

	getreport = () => {
		if (this.data_location.length < 1) {
			alert ("Choose a collector or capture set")
			return
		}

		this.setState ({spinner: true})
		axios.post ("/admin/elastic_assetinventory", {
			sess: GState.session,
			collector: this.data_location.join('|'),
			starttime: this.starttime,
			endtime: this.endtime,
			fields: this.fields.join('|')
		})
		.then (r=>{
			var ary = []
			for (const m in r.data.assets) {
				if ((parseInt (m.substring(0,2),16) & 1) === 0) { // ignore bcast and mcast
					r.data.assets[m]["mac"] = m
					ary.push (r.data.assets[m])
				}
			}
			ary.sort ((a,b)=>{return a["oui"] >= b["oui"]})
			this.setState ({spinner:false, assets: ary})
		})
		.catch (r=>{
			this.setState ({spinner:false, assets: []})
			alert (r)
		})
	}


	ippane = (props) => {
		if (props.iplist.length > 5) {
			const firstfive = props.iplist.slice(0,5).map ((ia,iix)=><div key={iix} style={styles.smallorange} >{ia}</div>)
			const q = props.iplist.map ((ia,iix)=><div key={iix}  >{ia}</div>)

			var ary = [[],[],[]]
			props.iplist.forEach ((m,i) => {
				ary[ i % 3 ].push (<div key={"q"+i} style={styles.smallorange} >{m}</div>)
			})

			return <div>
				{firstfive}
				<span style={styles.smalllink} id={"moreip_" + props.ix} >More IP Addresses ({props.iplist.length})...</span>
				<UncontrolledPopover trigger="legacy" target={"moreip_" + props.ix} >
					<PopoverHeader>
					</PopoverHeader>
					<PopoverBody>
					<Row>
					<Col><div style={{padding:"1em"}}>{ary[0]}</div></Col>
					<Col><div style={{padding:"1em"}}>{ary[1]}</div></Col>
					<Col><div style={{padding:"1em"}}>{ary[2]}</div></Col>
					</Row>
					</PopoverBody>
				</UncontrolledPopover>
				</div>
		}
		else {
			return props.iplist.map ((ia,iix)=><div key={iix} style={styles.smallorange} >{ia}</div>)
		}
	}

	render() {
		const list = this.state.assets.map ((m,ix)=> <ListGroupItem key={ix}>
				<div style={styles.bigtitle} >{m.oui}</div>
				<div style={styles.smalltitle}>{m.mac}</div>
				< this.ippane iplist={m.ipaddresses} ix={ix} />
				{ ("frames" in m) ? <div style={styles.smalltitle}>Frames: {m["frames"]}</div> : null }
				{ ("octets" in m) ? <div style={styles.smalltitle}>Octets: {m["octets"]}</div> : null }
				{ ("role" in m) ? <div style={styles.smalltitle}>Role: {m["role"]}</div> : null }
				{ ("applications" in m) ? <div style={styles.smalltitle}>Applications: {m["applications"].join(", ")}</div> : null }
				{ ("hostnames" in m) ? <div style={styles.smalltitle}>Hostnames: {m["hostnames"].join(", ")}</div> : null }
				{ ("flows" in m) ? <div style={styles.smalltitle}>Flows: {m["flows"].join(", ")}</div> : null }
				</ListGroupItem>)

		return <div>
				<h3 className="pageheader">Reports: Asset Inventory</h3>

				<Row>
				<Col>
					<div className={`timeme`}>
					<Timepicker picked={(st,et)=>{ this.starttime=st; this.endtime=et}} />
					</div>
				</Col>
				<Col>
					<div className={`timeme`}>
					<CollectorPicker picked={e=>{this.data_location=e}} />
					</div>

					<div className={`timeme`}>
					<FieldPicker notify={v=>{this.fields=v}} />
					</div>
				</Col>
				</Row>


				<div style={{height:"1em"}} />


				<Button color="link" size="sm" onClick={this.getreport}>Run report...</Button>
				<Spinner size="sm" color="warning" style={{visibility:this.state.spinner?"visible":"hidden"}} />

				<p style={{fontSize:"0.8em",color:"gray"}}>Assets: {this.state.assets.length}</p>

				<ListGroup>
				{list}
				</ListGroup>

			</div>
	}
}


const styles = {
	bigtitle: {
		fontSize: "1.2em",
		color: "gray"
	},
	smalltitle: {
		fontSize: "0.75em",
		color: "black"
	},
	lesssmalltext: {
		fontSize: "0.95em",
		color: "gray"
	},
	smalltext: {
		fontSize: "0.75em",
		color: "gray"
	},
	smallorange: {
		fontSize: "0.75em",
		color: "darkorange"
	},
	smalllink: {
		fontSize: "0.75em",
		color: "darkorange",
		fontWeight: "bold",
		cursor: "pointer"
	},
	smallgraylink: {
		fontSize: "0.75em",
		color: "darkblue"
	},
	smallalerttext: {
		fontSize: "0.75em",
		color: "red"
	}
};
