
import React,{Component} from 'react';
import {GState, EE, Superpowers} from './state';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	Label,
	Input
} from 'reactstrap';
import axios from 'axios';
import NotificationSystem from 'react-notification-system';


const SmallTitle = (props)=> <span style={styles.smalltitle}>{props.children}</span>
const SmallText = (props)=> <span style={styles.smalltext}>{props.children}</span>

const Item = (props)=> {
	var title = props.title
	var data = props.data
	var tail = ""

	switch (title) {
		case "spaceavailM":
			title = "Storage available"
			tail = " megabytes"
			break;
		default:
			break;
	}

	return <div>
		<SmallTitle>{title}: </SmallTitle>
		<SmallText>{data}{tail}</SmallText>
	</div>
}



/*************
SendTestEmail
*************/

class SendTestEmail extends Component {

	// Props
	//   close, a function telling the caller to close us out

	state = {
		recipient: "",
		subject: "",
		message: ""
	}

	send = ()=> {
		axios.post ("/admin/sendtestemail",  {
				sess: GState.session,
				recipient: this.state.recipient,
				subject: this.state.subject,
				message: this.state.message
			})
			.then (r=>{
				this.notes.current.addNotification ({message:"Sent email", level:"success", position:"tl"})
			})
			.catch (e=>{
				this.notes.current.addNotification ({message:String(e), level:"error", position:"tl"})
			})
	}

	notes = React.createRef();

	render() {
		return <Modal isOpen={true}>
			<ModalHeader>
			Send Test Email
			</ModalHeader>
			<ModalBody>
				<Form>
					<Label>Recipient</Label>
					<Input value={this.state.recipient} onChange={e=>{this.setState({recipient:e.target.value})}} />

					<Label>Subject</Label>
					<Input value={this.state.subject} onChange={e=>{this.setState({subject:e.target.value})}} />

					<Label>Message</Label>
					<Input type="textarea" value={this.state.message} onChange={e=>{this.setState({message:e.target.value})}} />
				</Form>
			</ModalBody>
			<ModalFooter>
				<Button size="sm" color="success" onClick={this.send}>Send</Button>
				<Button size="sm" onClick={this.props.close}>Close</Button>
			</ModalFooter>
			<NotificationSystem ref={this.notes} />
			</Modal>
	}
};

/************
SystemStatus
************/

export default class SystemStatus extends Component {

	state = {
		data: {},
		testemail: false
	}

	componentDidMount() {
		axios.post ("/admin/serverstatus", {
			sess: GState.session
		})
		.then (r=>{
			this.setState ({data:r.data})
			console.log (r.data)
		})
		.catch (r=>{
			alert (r)
		})
	}

	notes = React.createRef();
	addnote = e => {
		console.log ("too easy")
		this.notes.current.addNotification ({
			message: 'penix',
			level: 'success'
		})
	}

	render() {
		// A lot of this stuff is only available with superpowers on the home portal,
		// but available to anyone on E/E.

		const items = (()=>{
			var ary = []
			for (const i in this.state.data) {
				ary.push (<Item key={i} title={i} data={this.state.data[i]} />)
			}
			return ary
		}) ()

		return <div>
			<h3 className="pageheader">System Status</h3>

			<p/>
			<Superpowers>
			<p>
			<Button size="sm" color="link" onClick={()=>{this.setState({testemail:true})}}>Send Test Email</Button>
			</p>
			{ this.state.testemail ? <SendTestEmail close={()=>{this.setState({testemail:false})}} /> : null }
			</Superpowers>

			<EE> {items} </EE>
			<Superpowers> {items} </Superpowers>

			<Button onClick={this.addnote}>AddNote</Button>
			<NotificationSystem ref={this.notes} />
			</div>
	}
}


const styles = {
	smalltitle: {
		fontSize: "0.75em"
	},
	smalltext: {
		fontSize: "0.75em",
		color: "gray"
	}
}

