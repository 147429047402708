
import React,{Component} from 'react';

import {scaleLinear} from 'd3-scale';
import {select} from 'd3-selection';
import {max} from 'd3-array';
import axios from 'axios';


export default class CollectorUptime extends Component {

	// Props
	//  sess, a session key
	//  assettag, a string

	state: {
	}
	

	componentDidMount() {
		axios.post ("/admin/collectoruptime", {
			sess: this.props.sess,
			assettag: this.props.assettag
		})
		.then (r=>{
			if (r.data.length > 1) {
				//const ca = r.data.map ((m,ix) => m["Createdat"]).reverse()
				//const ca2 = ca.slice(0,ca.length-1).map ((m,ix) => ca[ix+1] - ca[ix])

				const da = r.data.map ((m,ix) => parseInt(m["Createdat"]))
				const min_da = da[da.length - 1]
				const max_da = da[0]
				const diff = max_da - min_da
				const nslots = diff / 20.0
				var arr = Array(Math.ceil(nslots)).fill(0)

				da.forEach (m=> { arr[Math.floor((m-min_da)/20.0) ] = 1})

				this.showchart (arr)
			}
		})
		.catch (e=>{
			alert (e)
		})
	}

	showchart = data => {
		const node = this.node
		const yScale = scaleLinear()
			.domain ([0,max(data)])
			.range ([0,500])


		select (node)
			.selectAll ('rect')
			.data (data)
			.enter()
			.append ('rect')

		select (node)
			.selectAll ('rect')
			.data (data)
			.exit()
			.remove()

		select (node)
			.selectAll ('rect')
			.data (data)
			.style ('fill', '#fe9922')
			.attr ('x', (d,i)=>i*1000/data.length)
			.attr ('y', d => 500 - yScale(d))
			.attr ('height', d => yScale(d))
			.attr ('width', 1000/data.length)

	}


	render() {
		return <div>
		{this.props.assettag}

			<div style={{border:"1px solid black",margin:"1em"}}>
			<svg ref={n=> this.node=n} width={1000} height={500} />
			</div>

			</div>
	}
}


