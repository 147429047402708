
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {Component} from 'react';


export var GState = observable({
	collector_status: {},
	superpowers: false,
	enterpriseedition: false,
	session: "",
	sessprops: {},

	// The following is a counter that anyone can set and anyone can
	// observe. Its only semantics for an observer is that something
	// changed (like a field was edited), which should trigger a view
	// refresh. It's completely generic and intended to be used with
	// autorun functions.
	refreshview: 0
});


export const Superpowers = observer (class _su extends Component {
	render() {
		var su = GState.superpowers;
		if (this.props.reverse)
			su = !su;
		return su ? this.props.children : null;
	}
});

export const EE = observer (class _ee extends Component {
	render() {
		var ee = GState.enterpriseedition;
		if (this.props.reverse)
			ee = !ee;
		return ee ? this.props.children : null;
	}
});
