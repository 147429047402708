

import React,{Component} from 'react';
import {
	Row,
	Col,
	Button
} from 'reactstrap';


export default class BaseballCard extends Component {

	// Props:
	//   data, an object about a device
	//   listby, a string containing blank or "ip" (for now)

	state = {
		detail: false
	}

	componentDidUpdate() {
	}

	toggle=()=> {
		this.setState ({detail: !this.state.detail})
	}

	etypes = m => {
		const translate = q => {
			switch (q) {
				case "0x0800": return "IPv4";
				case "0x0806": return "ARP";
				case "0x8100": return "802.1q";
				case "0x86dd": return "IPv6";
				case "0x88cc": return "LLDP";
				case "0x8874": return "Broadcom tag protocol";
				default: return q;

			}
		}

		const n = m["ethertypes"]
		const k = n ? Object.keys (n) : []
		const d = k.map ((m,ix)=><div key={ix} >{translate(m)}: {n[m]}</div>)
		return <div style={{fontSize:"0.8em",color:"darkgray"}}>
		{d}
		</div>
	}


	listbyip=(m)=>{


		if (m["ipaddresses"])
			 m["ipaddresses"].sort ((a,b)=> {
				 var aw = a.includes(".") && a !== "0.0.0.0"
					 ? 1
					 : a.includes (":")
					 ? 2
					 : 3;
				 var bw = b.includes(".") && b !== "0.0.0.0"
					 ? 1
					 : b.includes (":")
					 ? 2
					 : 3;
				 return aw < bw
					 ? -1
					 : aw === bw
					 ? 0
					 : 1;
			 })



		const ips = m["ipaddresses"] ? m["ipaddresses"].map ((m,ix)=> <div key={ix} style={styles.ip} >{m}</div>) : null;
		const flows = m["flows"] ? m["flows"].map ((m,ix)=> <div key={ix} style={styles.ip} >{m}</div>) : null;
		const dnshosts = m["dnshosts"] ? m["dnshosts"].map ((m,ix)=> <div key={ix} style={styles.ip} >{m}</div>) : null;
		const hostnames = m["hostnames"] ? m["hostnames"].map ((m,ix)=> <div key={ix} style={styles.ip} >{m}</div>) : null;
		const apps = m["applications"] ? m["applications"].map ((m,ix)=> <div key={ix} style={styles.ip} >{m}</div>) : null;


		const iptitle = (m["ipaddresses"] && m["ipaddresses"].length > 0)
			?  <div style={{fontSize:"1.3em", color:"gray", paddingBottom:"0.2em"}} >{m["ipaddresses"][0]}</div>
			:  <div style={{fontSize:"1.3em", color:"gray", paddingBottom:"0.2em"}} >No IP Address</div>

		if (this.state.detail === false)
			return <Row>
				<Col>
				{iptitle}
				<div style={{fontSize:"0.75em", color:"midnightblue", paddingBottom:"0.2em"}} >{m["oui"]}</div>
				<div style={{fontSize:"0.9em", color:"steelblue", paddingBottom:"0.2em"}} >{m["deviceid"]}</div>
				<div style={{fontSize:"0.75em", color:"midnightblue",paddingBottom:"0.5em"}} >{m["mac"]}</div>
				<Button color="link" style={{color:"darkorange",fontSize:"0.8em"}} onClick={e=>{this.toggle()}} >Detail &raquo;</Button>
				</Col>
				<Col>
				<div style={{fontSize:"0.75em", color:"gray"}}>
					<div>IP addresses: {ips ? ips.length : 0}</div>
					<div>Flows: {flows ? flows.length : 0}</div>
					<div>Applications: {apps ? apps.length : 0}</div>
					<div>Hostnames: {hostnames ? hostnames.length : 0}</div>
					<div>DNS hosts: {dnshosts ? dnshosts.length : 0}</div>
				</div>
				</Col>
				<Col/>
			</Row>

		else
			return <Row>
				<Col>
				{iptitle}
				<div style={{fontSize:"0.75em", color:"midnightblue", paddingBottom:"0.2em"}} >{m["oui"]}</div>
				<div style={{fontSize:"0.9em", color:"steelblue", paddingBottom:"0.2em"}} >{m["deviceid"]}</div>
				<div style={{fontSize:"0.75em", color:"midnightblue", paddingBottom:"0.5em"}} >{m["mac"]}</div>
				<Button color="link" style={{color:"darkorange",fontSize:"0.8em"}} onClick={e=>{this.toggle()}} >Hide Detail &raquo;</Button>
				</Col>
				<Col>
				<div style={{backgroundColor:"lightcyan"}}>
				<div style={{fontSize:"0.8em", marginBottom:"0.2em", backgroundColor:"lightcyan"}}>IP Addresses</div>
				{ips}
				</div>
				<hr />
				<div style={{backgroundColor:"lightcyan"}}>
				<div style={{fontSize:"0.8em", marginBottom:"0.2em", backgroundColor:"lightcyan"}}>Hostnames</div>
				{hostnames}
				</div>
				<hr />
				<div style={{backgroundColor:"lightcyan"}}>
				<div style={{fontSize:"0.8em", marginBottom:"0.2em"}}>Flows</div>
				{flows}
				</div>
				</Col>
				<Col>
				<div style={{backgroundColor:"lightcyan"}}>
				<div style={{fontSize:"0.8em", marginBottom:"0.2em"}}>DNS hosts</div>
				{dnshosts}
				</div>
				<hr />
				<div style={{backgroundColor:"lightcyan"}}>
				<div style={{fontSize:"0.8em", marginBottom:"0.2em"}}>Applications</div>
				{apps}
				</div>
				<hr />
				<div style={{backgroundColor:"lightcyan"}}>
				<div style={{fontSize:"0.8em", marginBottom:"0.2em"}}>Packet counts by Ethertype</div>
				{this.etypes(m)}
				</div>
				</Col>
			</Row>
	}

	listbyether=(m)=>{
		const ips = m["ipaddresses"] ? m["ipaddresses"].map ((m,ix)=> <div key={ix} style={styles.ip} >{m}</div>) : null;
		const flows = m["flows"] ? m["flows"].map ((m,ix)=> <div key={ix} style={styles.ip} >{m}</div>) : null;
		const dnshosts = m["dnshosts"] ? m["dnshosts"].map ((m,ix)=> <div key={ix} style={styles.ip} >{m}</div>) : null;
		const hostnames = m["hostnames"] ? m["hostnames"].map ((m,ix)=> <div key={ix} style={styles.ip} >{m}</div>) : null;
		const apps = m["applications"] ? m["applications"].map ((m,ix)=> <div key={ix} style={styles.ip} >{m}</div>) : null;


		if (this.state.detail === false)
			return <Row>
				<Col>
				<div style={{fontSize:"1.3em", color:"gray", paddingBottom:"0.2em"}} >{m["oui"]}</div>
				<div style={{fontSize:"0.9em", color:"steelblue", paddingBottom:"0.2em"}} >{m["deviceid"]}</div>
				<div style={{fontSize:"0.75em", color:"midnightblue",paddingBottom:"0.5em"}} >{m["mac"]}</div>
				<Button color="link" style={{color:"darkorange",fontSize:"0.8em"}} onClick={e=>{this.toggle()}} >Detail &raquo;</Button>
				</Col>
				<Col>
				<div style={{fontSize:"0.75em", color:"gray"}}>
					<div>IP addresses: {ips ? ips.length : 0}</div>
					<div>Flows: {flows ? flows.length : 0}</div>
					<div>Applications: {apps ? apps.length : 0}</div>
					<div>Hostnames: {hostnames ? hostnames.length : 0}</div>
					<div>DNS hosts: {dnshosts ? dnshosts.length : 0}</div>
				</div>
				</Col>
				<Col/>
			</Row>

		else
			return <Row>
				<Col>
				<div style={{fontSize:"1.3em", color:"gray", paddingBottom:"0.2em"}} >{m["oui"]}</div>
				<div style={{fontSize:"0.9em", color:"steelblue", paddingBottom:"0.2em"}} >{m["deviceid"]}</div>
				<div style={{fontSize:"0.75em", color:"midnightblue", paddingBottom:"0.5em"}} >{m["mac"]}</div>
				<Button color="link" style={{color:"darkorange",fontSize:"0.8em"}} onClick={e=>{this.toggle()}} >Hide Detail &raquo;</Button>
				</Col>
				<Col>
				<div style={{backgroundColor:"lightcyan"}}>
				<div style={{fontSize:"0.8em", marginBottom:"0.2em", backgroundColor:"lightcyan"}}>IP Addresses</div>
				{ips}
				</div>
				<hr />
				<div style={{backgroundColor:"lightcyan"}}>
				<div style={{fontSize:"0.8em", marginBottom:"0.2em", backgroundColor:"lightcyan"}}>Hostnames</div>
				{hostnames}
				</div>
				<hr />
				<div style={{backgroundColor:"lightcyan"}}>
				<div style={{fontSize:"0.8em", marginBottom:"0.2em"}}>Flows</div>
				{flows}
				</div>
				</Col>
				<Col>
				<div style={{backgroundColor:"lightcyan"}}>
				<div style={{fontSize:"0.8em", marginBottom:"0.2em"}}>DNS hosts</div>
				{dnshosts}
				</div>
				<hr />
				<div style={{backgroundColor:"lightcyan"}}>
				<div style={{fontSize:"0.8em", marginBottom:"0.2em"}}>Applications</div>
				{apps}
				</div>
				<hr />
				<div style={{backgroundColor:"lightcyan"}}>
				<div style={{fontSize:"0.8em", marginBottom:"0.2em"}}>Packet counts by Ethertype</div>
				{this.etypes(m)}
				</div>
				</Col>
			</Row>
	}

	render() {
		return this.props.listby === ""
			? this.listbyether (this.props.data)
			: this.listbyip (this.props.data);
	}

}

const styles = {
	ip: {
		fontSize:"0.8em",
		color: "gray"
	}
};
