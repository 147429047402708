
import React,{Component} from 'react';
import moment from 'moment'
import {
	UncontrolledPopover,
	PopoverHeader,
	PopoverBody,
	Input,
	InputGroup,
	InputGroupButtonDropdown,
	Dropdown,
	DropdownItem,
	DropdownToggle,
	DropdownMenu,
	Button,
	Badge
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import momentLocalizer from 'react-widgets-moment';
import {DateTimePicker} from 'react-widgets';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-widgets/dist/css/react-widgets.css';

moment.locale ('EN')
momentLocalizer()

export default class tp extends Component {

	// Props:
	//   picked, a function taking a starttime and an enddate, which we call when
	//   the user picks a time range.

	state = {
		startdate: new Date() - 3600000,
		enddate: new Date(),
		pickunit: false,
		duration: 1,
		unit: "hour"
	}


	componentDidMount() {
		this.latest()
	}

	notify_caller = ()=> {
		//const y = moment (this.state.startdate).add (this.state.duration, this.state.unit).toDate()
		this.props.picked (new Date(this.state.startdate), new Date(this.state.enddate))
	}

	changeduration = e => {
		var t = e.target.valueAsNumber
		if (isNaN (t) || t === 0)
			t = 1
		this.setState ({duration: t})
	}

	previous=()=>{
		const y = moment (this.state.startdate).subtract (this.state.duration, this.state.unit).toDate()
		this.setState ({startdate:y})
	}

	next=()=>{
		const y = moment (this.state.startdate).add (this.state.duration, this.state.unit).toDate()
		this.setState ({startdate:y})
	}

	latest=()=>{
		const y = moment().subtract (this.state.duration, this.state.unit).toDate()
		this.setState ({startdate:y})
	}

	latest2 = (unit) => {
		if (unit === "10") {
			const y = moment().subtract (10, "minute").toDate()
			this.setState ({duration:10, unit:"minute", startdate:y, enddate:new Date()})
		}
		else {
			const y = moment().subtract (1, unit).toDate()
			this.setState ({duration:1, unit:unit, startdate:y, enddate:new Date()})
		}
	}

	render() {

		// We come here everytime we make a change, so it's a good place to tell the caller.
		this.notify_caller()

		const plural = this.state.duration === 1 ? "" : "s";
		const showunit = this.state.unit === "minute"
			? "Minute" + plural
			: this.state.unit === "hour"
			? "Hour" + plural
			: this.state.unit === "day"
			? "Day" + plural
			: this.state.unit === "week"
			? "Week" + plural
			: this.state.unit === "month"
			? "Month" + plural
			: this.state.unit === "year"
			? "Year" + plural
			: "[" + this.state.unit + "]"
			;

		return <div style={{color:"gray",fontSize:"0.8rem"}} >

			Start:
			<div style={{maxWidth:"16em"}}>
			<DateTimePicker value={this.state.startdate} onChange={e=>{this.setState({startdate:e})}} step={5} />
			</div>
			End:
			<div style={{maxWidth:"16em"}}>
			<DateTimePicker value={this.state.enddate} onChange={e=>{this.setState({enddate:e})}} step={5} />
			</div>

			{/*
			<br/>
			Start report on:{" "}

			<DatePicker
				className="timepicker"
				selected={this.state.startdate}
				onChange={d=>{this.setState({startdate:d})}}
				dateFormat = "d MMM YYYY"
			/>

			At:{" "}
			<DatePicker
				className="timepicker"
				selected={this.state.startdate}
				onChange={d=>{this.setState({startdate:d})}}
				showTimeSelect
				showTimeSelectOnly
				dateFormat="h:mm aa"
				timeCaption="Time"
				timeIntervals={10}
			/>


			For:{" "}
			<span id="tp_duration" className="timepicker" >{this.state.duration} {showunit}</span>

			<UncontrolledPopover trigger="legacy" target="tp_duration" placement="bottom-start">
			<PopoverHeader>
			<span style={{fontWeight:"bold"}}>Duration</span>
			</PopoverHeader>
			<PopoverBody>

			<InputGroup>
			<Input size="sm" value={this.state.duration} onChange={this.changeduration} type="number" min={1} max={100} step={1} />
			<InputGroupButtonDropdown size="sm" addonType="append" isOpen={this.state.pickunit} toggle={e=>{this.setState({pickunit:!this.state.pickunit})}} >
				<DropdownToggle caret>
				{showunit}
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem onClick={e=>{this.setState({unit:"minute"})}}>
					Minutes
					</DropdownItem>
					<DropdownItem onClick={e=>{this.setState({unit:"hour"})}}>
					Hours
					</DropdownItem>
					<DropdownItem onClick={e=>{this.setState({unit:"day"})}}>
					Days
					</DropdownItem>
					<DropdownItem onClick={e=>{this.setState({unit:"week"})}}>
					Weeks
					</DropdownItem>
					<DropdownItem onClick={e=>{this.setState({unit:"month"})}}>
					Months
					</DropdownItem>
					<DropdownItem onClick={e=>{this.setState({unit:"year"})}}>
					Years
					</DropdownItem>
				</DropdownMenu>
			</InputGroupButtonDropdown>
			</InputGroup>


			</PopoverBody>
			</UncontrolledPopover>


			<div>
			<Button color="link" style={{color:"darkorange",fontSize:"0.8rem"}} onClick={this.previous}>Previous</Button>
			<Button color="link" style={{color:"darkorange",fontSize:"0.8rem"}} onClick={this.next}>Next</Button>
			<Button color="link" style={{color:"darkorange",fontSize:"0.8rem"}} onClick={this.latest}>Latest</Button>
			</div>
			*/}

			<p />
			<div>
			Latest:
			<span className="underliner" style={styles.unitlink} onClick={()=>{this.latest2("10")}}>10 mins</span>
			<span className="underliner" style={styles.unitlink} onClick={()=>{this.latest2("hour")}}>Hour</span>
			<span className="underliner" style={styles.unitlink} onClick={()=>{this.latest2("day")}}>Day</span>
			<span className="underliner" style={styles.unitlink} onClick={()=>{this.latest2("week")}}>Week</span>
			<span className="underliner" style={styles.unitlink} onClick={()=>{this.latest2("month")}}>Month</span>
			<span className="underliner" style={styles.unitlink} onClick={()=>{this.latest2("year")}}>Year</span>

			</div>

			</div>
	}
}

const styles = {
	unitlink: {
		color: "darkorange",
		padding: "0 0.5em 0 0.5em",
		cursor: "pointer"
	}
};


