
import {Component} from 'react'
import {GState} from './state';
import axios from 'axios';

export default class CollectorStatusUpdater extends Component {

	componentDidMount() {
		this.updatecollectorstatus()
		this.intval = setInterval (this.updatecollectorstatus, 15000)
	}
	componentWillUnmount() {
		clearInterval (this.intval)
	}

	render() {
		return null;
	}

	updatecollectorstatus=()=> {
		axios.post ("/admin/collectorliststatus", {
			sess: GState.session
		})
		.then (r=>{
			for (const i in r.data) {
				r.data[i].Status = JSON.parse (r.data[i].Status)
			}
			GState.collector_status = r.data
		})
		.catch (e=>{
		})
	}
}


